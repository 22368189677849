// Services.tsx
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import ScrollToTopButton from "../../../components/ScrollToTopButton";
import ServiceSection from "./ServicesSectionLeft";
import Coding from "../../../assets/coding.jpg";
import AI from "../../../assets/ai.jpg";
import IT from "../../../assets/it.jpg";
import WebSites from "../../../assets/websites.jpg";
import Robotics from "../../../assets/robotics.jpg";
import Cybersecurity from "../../../assets/cybersecurity.jpeg";
import { Screens, SelectedPage } from "../../../shared/types";
import ServiceSectionRight from "./ServicesSectionRight";
import useMediaQuery from "../../../hooks/useMediaQuery";
import ServiceSectionMobile from "./ServicesSectionMobile";

type Props = {
  selectedDiv: String;
  setSelectedDiv: (value: string) => void;
  isMenuToggled: boolean;
  setIsMenuToggled: (value: boolean) => void;
  setSelectedPage(value: SelectedPage): void;
};

const Services: React.FC<Props> = ({
  selectedDiv,
  setSelectedDiv,
  isMenuToggled,
  setIsMenuToggled,
  setSelectedPage,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const scrollToRef = () => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const { t, i18n } = useTranslation();

  const isAboveHugeScreen = useMediaQuery(Screens.Huge);
  const isAboveLargeScreen = useMediaQuery(Screens.Large);
  const isAboveMediumScreen = useMediaQuery(Screens.Medium);
  const isAboveSmallScreen = useMediaQuery(Screens.Small);

  return (
    <main
      id="services"
      style={{ padding: 0, margin: 0, boxSizing: "border-box" }}
      className="no-scrollbar flex-1 snap-y snap-mandatory  overflow-y-auto overflow-x-hidden"
    >
      {/* Service Section 1 */}
      <div
        id="service1"
        onMouseEnter={() => setSelectedDiv("service1")}
        ref={ref}
      >
        {isAboveMediumScreen ? (
          <ServiceSection
            title={t("Coding")}
            description={t("codingContentSmall")}
            imageUrl={Coding}
            setSelectedPage={setSelectedPage}
          />
        ) : (
          <ServiceSectionMobile
            title={t("Coding")}
            description={t("codingContentSmall")}
            imageUrl={Coding}
            setSelectedPage={setSelectedPage}
          />
        )}
      </div>

      {/* Service Section 2 */}
      <div id="service2" onMouseEnter={() => setSelectedDiv("service2")}>
        {isAboveMediumScreen ? (
          <ServiceSectionRight
            title={t("A.I.")}
            description={t("aiContentSmall")}
            imageUrl={AI}
            setSelectedPage={setSelectedPage}
          />
        ) : (
          <ServiceSectionMobile
            title={t("A.I.")}
            description={t("aiContentSmall")}
            imageUrl={AI}
            setSelectedPage={setSelectedPage}
          />
        )}
      </div>

      <div id="service3" onMouseEnter={() => setSelectedDiv("service3")}>
        {isAboveMediumScreen ? (
          <ServiceSection
            title={t("Robotics")}
            description={t("roboticsContentSmall")}
            imageUrl={Robotics}
            setSelectedPage={setSelectedPage}
          />
        ) : (
          <ServiceSectionMobile
            title={t("Robotics")}
            description={t("roboticsContentSmall")}
            imageUrl={Robotics}
            setSelectedPage={setSelectedPage}
          />
        )}
      </div>

      <div id="service4" onMouseEnter={() => setSelectedDiv("service4")}>
        {isAboveMediumScreen ? (
          <ServiceSectionRight
            title={t("WebSites")}
            description={t("webSiteContentSmall")}
            imageUrl={WebSites}
            setSelectedPage={setSelectedPage}
          />
        ) : (
          <ServiceSectionMobile
            title={t("WebSites")}
            description={t("webSiteContentSmall")}
            imageUrl={WebSites}
            setSelectedPage={setSelectedPage}
          />
        )}
      </div>

      <div id="service5" onMouseEnter={() => setSelectedDiv("service5")}>
        {isAboveMediumScreen ? (
          <ServiceSection
            title={t("Cybersecurity")}
            description={t("cybersecurityContentSmall")}
            imageUrl={Cybersecurity}
            setSelectedPage={setSelectedPage}
          />
        ) : (
          <ServiceSectionMobile
            title={t("Cybersecurity")}
            description={t("cybersecurityContentSmall")}
            imageUrl={Cybersecurity}
            setSelectedPage={setSelectedPage}
          />
        )}
      </div>

      <div id="service6" onMouseEnter={() => setSelectedDiv("service6")}>
        {isAboveMediumScreen ? (
          <ServiceSectionRight
            title={t("ourServices")}
            description={t("itServicesContentSmall")}
            imageUrl={IT}
            setSelectedPage={setSelectedPage}
          />
        ) : (
          <ServiceSectionMobile
            title={t("ourServices")}
            description={t("itServicesContentSmall")}
            imageUrl={IT}
            setSelectedPage={setSelectedPage}
          />
        )}
      </div>

      <div
        className={`${
          selectedDiv === "service1" || isMenuToggled
            ? "opacity-0"
            : "opacity-100"
        } transition-opacity duration-300 ease-in-out `}
        onClick={scrollToRef}
      >
        <ScrollToTopButton />
      </div>
    </main>
  );
};

export default Services;
