import React, { useState, useEffect, useRef } from "react";

interface PaddleProps {
  x: number;
  y: number;
  isMovingLeft: boolean;
  isMovingRight: boolean;
  setIsMovingLeft: (value: boolean) => void;
  setIsMovingRight: (value: boolean) => void;
  initialPosition: { x: number; y: number; width: number; height: number };
}

const Paddle: React.FC<PaddleProps> = ({
  x,
  y,
  isMovingLeft,
  isMovingRight,
  setIsMovingLeft,
  setIsMovingRight,
  initialPosition,
}) => {
  const [paddleX, setPaddleX] = useState(initialPosition.x);
  const [paddleY, setPaddleY] = useState(initialPosition.y);
  const paddleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setPaddleX(x);
    setPaddleY(y);
  }, [x, y]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "ArrowLeft") {
        setIsMovingLeft(true);
      } else if (event.key === "ArrowRight") {
        setIsMovingRight(true);
      } else if (event.key === "ArrowUp" || event.key === "ArrowDown") {
        event.preventDefault();
      }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key === "ArrowLeft") {
        setIsMovingLeft(false);
      } else if (event.key === "ArrowRight") {
        setIsMovingRight(false);
      } else if (event.key === "ArrowUp" || event.key === "ArrowDown" ) {
        event.preventDefault();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (isMovingLeft) {
      interval = setInterval(() => {
        setPaddleX((prevX) => {
          const newX = prevX - 10;
          if (newX < 32) {
            return 0;
          } else {
            return newX;
          }
        });
      }, 10);
    } else if (isMovingRight) {
      interval = setInterval(() => {
        setPaddleX((prevX) => {
          const newX = prevX + 10;
          const maxRight =
            initialPosition.width - (paddleRef.current?.offsetWidth || 0) -64;
          if (newX > maxRight) {
            return maxRight;
          } else {
            return newX;
          }
        });
      }, 10);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isMovingLeft, isMovingRight, initialPosition.width]);

  return (
    <div
      ref={paddleRef}
      className="paddle absolute h-8 w-1/12 bg-white"
      style={{
        left: paddleX,
        top: paddleY,
      }}
    ></div>
  );
};

export default Paddle;
