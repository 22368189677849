import { useState, useRef } from "react";
import FirstPage from "../../../components/firstPage";
import ContentPage from "../../../components/page";
import ScrollToTopButton from "../../../components/ScrollToTopButton";
import useMediaQuery from "../../../hooks/useMediaQuery";
import { Screens, SelectedPage } from "../../../shared/types";
import { useTranslation } from "react-i18next";
import VideoPlayer from "../../../components/VideoPlayer";
import Team from "../home/components/team";
// Importa le immagini
import ceh1 from "../../../assets/certificates/ceh1.png";
import ceh2 from "../../../assets/certificates/ceh2.png";
import plc1 from "../../../assets/certificates/plc1.png";
import plc2 from "../../../assets/certificates/plc2.png";
// Array di certificati
const certificates = [ceh1, ceh2, plc1, plc2];

type Props = {
  selectedDiv: String;
  setSelectedDiv: (value: String) => void;
  isMenuToggled: boolean;
  setIsMenuToggled: (value: boolean) => void;
  setSelectedPage: (value: SelectedPage) => void;
};

const Certificates = ({
  selectedDiv,
  setSelectedDiv,
  isMenuToggled,
  setIsMenuToggled,
  setSelectedPage,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedCert, setSelectedCert] = useState<string | null>(null);

  const handleCertificateClick = (cert: string) => {
    setSelectedCert(cert);
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setSelectedCert(null);
  };

  const scrollToRef = () => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const isAboveHugeScreen = useMediaQuery(Screens.Huge);
  const isAboveLargeScreen = useMediaQuery(Screens.Large);
  const isAboveMediumScreen = useMediaQuery(Screens.Medium);
  const isAboveSmallScreen = useMediaQuery(Screens.Small);

  function getPageDimens(): String {
    if (isAboveHugeScreen) {
      return "h-[calc(100vh-180px)]]";
    } else if (isAboveLargeScreen) {
      return " h-[calc(100vh-180px)]";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return " h-[calc(100vh-120px)]";
    } else {
      return "h-[calc(100vh-120px)]]";
    }
  }

  const handleBackgroundClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      closePopup();
    }
  };

  const { t, i18n } = useTranslation();

  return (
    <main
      id="aboutPage"
      style={{ padding: 0, margin: 0, boxSizing: "border-box" }}
      className={`relative  w-full snap-end  bg-cover bg-center bg-no-repeat p-[calc(60px)]`}
    >
      <div className="grid grid-cols-1 gap-4 p-4 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">
        {certificates.map((cert, index) => (
          <div key={index} className="relative">
            <img
              src={cert}
              alt={`Certificate ${index + 1}`}
              className="h-auto w-full cursor-pointer object-cover"
              onClick={() => handleCertificateClick(cert)}
            />
          </div>
        ))}
      </div>

      {isPopupOpen && selectedCert && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
          onClick={handleBackgroundClick}
        >
          <div className="relative rounded-lg bg-white p-4 shadow-lg">
            <button
              className="absolute right-4  top-4 text-2xl text-black"
              onClick={closePopup}
            >
              x
            </button>
            <img
              src={selectedCert}
              alt="Selected Certificate"
              className="max-h-full max-w-full object-contain"
            />
          </div>
        </div>
      )}
    </main>
  );
};

export default Certificates;
