// Services.tsx
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import ScrollToTopButton from "../../../components/ScrollToTopButton";
import ServiceSection from "./ServicesSection"; // Import the new component

import Wave from "../../../assets/wave.jpg";
import Tender from "../../../assets/tender.jpg";
import { Screens, SelectedPage } from "../../../shared/types";
import ProjectSectionLeft from "./ServicesSectionLeft";
import ProjectSectionRight from "./ServicesSectionRight";
import useMediaQuery from "../../../hooks/useMediaQuery";
import ProjectSectionMobile from "./ServicesSectionMobile";

type Props = {
  selectedDiv: String;
  setSelectedDiv: (value: string) => void;
  isMenuToggled: boolean;
  setIsMenuToggled: (value: boolean) => void;
  setSelectedPage(value: SelectedPage): void;
};

const Projects: React.FC<Props> = ({
  selectedDiv,
  setSelectedDiv,
  isMenuToggled,
  setIsMenuToggled,
  setSelectedPage,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const scrollToRef = () => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const { t, i18n } = useTranslation();

  const isAboveHugeScreen = useMediaQuery(Screens.Huge);
  const isAboveLargeScreen = useMediaQuery(Screens.Large);
  const isAboveMediumScreen = useMediaQuery(Screens.Medium);
  const isAboveSmallScreen = useMediaQuery(Screens.Small);

  return (
    <main
      id="services"
      style={{ padding: 0, margin: 0, boxSizing: "border-box" }}
      className="no-scrollbar flex-1 snap-y snap-mandatory  overflow-y-auto overflow-x-hidden"
    >
      {/* Service Section 1 */}
      <div
        id="service1"
        onMouseEnter={() => setSelectedDiv("service1")}
        ref={ref}
      >
        {isAboveMediumScreen ? (
          <ProjectSectionLeft
            title={t("Wave")}
            description={t("waveProject")}
            imageUrl={Wave}
            setSelectedPage={setSelectedPage}
          />
        ) : (
          <ProjectSectionMobile
            title={t("Wave")}
            description={t("waveProject")}
            imageUrl={Wave}
            setSelectedPage={setSelectedPage}
          />
        )}
      </div>

      <div id="service2" onMouseEnter={() => setSelectedDiv("service2")}>
        {isAboveMediumScreen ? (
          <ProjectSectionRight
            title={t("BotTender")}
            description={t("tenderProject")}
            imageUrl={Tender}
            setSelectedPage={setSelectedPage}
          />
        ) : (
          <ProjectSectionMobile
            title={t("BotTender")}
            description={t("tenderProject")}
            imageUrl={Tender}
            setSelectedPage={setSelectedPage}
          />
        )}
      </div>

      <div
        className={`${
          selectedDiv === "service1" || isMenuToggled
            ? "opacity-0"
            : "opacity-100"
        } transition-opacity duration-300 ease-in-out `}
        onClick={scrollToRef}
      >
        <ScrollToTopButton />
      </div>
    </main>
  );
};

export default Projects;
