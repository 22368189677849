import { useCallback, useRef } from "react";
import FirstPage from "../../../components/firstPage";
import ContentPage from "../../../components/page";
import ScrollToTopButton from "../../../components/ScrollToTopButton";
import Pic from "../../../assets/logo_transp_512.png";
import ContentPageMobile from "../../../components/pageMobile";
import { useTranslation } from "react-i18next";

type Props = {
  selectedDiv: String;
  setSelectedDiv: (value: String) => void;
  isMenuToggled: boolean;
  setIsMenuToggled: (value: boolean) => void;
};

const PrivacyPolicy = ({
  selectedDiv,
  setSelectedDiv,
  isMenuToggled,
  setIsMenuToggled,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  const scrollToRef = () => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const { t, i18n } = useTranslation();

  return (
    <main
      id="privacyPolicy"
      style={{ padding: 0, margin: 0, boxSizing: "border-box" }}
      className="no-scrollbar flex-1 snap-y snap-mandatory    overflow-y-auto overflow-x-hidden"
    >
      <div>
        <div
          onMouseEnter={() => {
            setSelectedDiv("secondPage");
          }}
        >
          <ContentPageMobile
            pic={Pic}
            pageContent={
              <div>
                {" "}
                <p className="mb-4  font-semibold text-cobalt">
                  {" "}
                  {t("privacyTitle")}
                </p>
                <p className="mb-4  font-semibold text-cobalt">
                  {" "}
                  {t("effectiveDate")}{" "}
                  <span className="font-norma text-black">09/07/2024</span>
                </p>
                <p className="mb-8 ">{t("privacyParagraph")}</p>
                <p className="mb-4  font-semibold text-cobalt">
                  {" "}
                  {t("privacyTitle1")}
                </p>
                <p className="mb-8 ">{t("privacy2")}</p>
                <p className="mb-4  font-semibold text-cobalt">
                  {" "}
                  {t("privacyTitle2")}
                </p>
                <p className="mb-8 ">{t("privacy3")}</p>{" "}
                <ul className="mb-8 list-disc pl-6">
                  {(t("privacy3List", { returnObjects: true }) as string[]).map(
                    (item: string, index: number) => (
                      <li key={index}>{item}</li>
                    )
                  )}
                </ul>
                <p className="mb-4  font-semibold text-cobalt">
                  {" "}
                  {t("privacyTitle3")}
                </p>
                <p className="mb-8 ">{t("privacy4")}</p>
                <p className="mb-4  font-semibold text-cobalt">
                  {" "}
                  {t("privacyTitle4")}
                </p>
                <p className="mb-8 ">{t("privacy5")}</p>
                <ul className="mb-8 list-disc pl-6">
                  {(t("privacy5List", { returnObjects: true }) as string[]).map(
                    (item: string, index: number) => (
                      <li key={index}>{item}</li>
                    )
                  )}
                </ul>
                <p className="mb-8 ">{t("privacy5End")}</p>
                <p className="mb-8 ">{t("privacy5EndEnd")}</p>
                <p className="mb-4  font-semibold text-cobalt">
                  {" "}
                  {t("privacyTitle5")}
                </p>
                <p className="mb-8 ">{t("privacy6")}</p>
                <p className="mb-8 ">{t("privacy6a")}</p>
                <p className="mb-8 ">{t("privacy6b")}</p>
                <p className="mb-4  font-semibold text-cobalt">
                  {" "}
                  {t("privacyTitle6")}
                </p>{" "}
                <p className="mb-8 ">{t("privacy7")}</p>
                <p className="mb-4  font-semibold text-cobalt">
                  {" "}
                  {t("privacyTitle7")}
                </p>{" "}
                <p className="mb-8 ">{t("privacy8")}</p>
                <p className="mb-4  font-semibold text-cobalt">
                  {" "}
                  {t("privacyTitle8")}
                </p>{" "}
                <p className="mb-8 ">{t("privacy9")}</p>
                <ul className="mb-8 list-disc pl-6">
                  {(t("privacy9List", { returnObjects: true }) as string[]).map(
                    (item: string, index: number) => (
                      <li key={index}>{item}</li>
                    )
                  )}
                </ul>
               
                <p className="mb-8 ">
                  <p className="mb-8 ">{t("privacy10")}</p>
                </p>
                <p className="mb-8 ">{t("privacy11")}</p>
                <p className="mb-8 ">{t("privacy12")}</p>
                <p className="mb-8 ">Cassino 09/09/2024</p>
                <p className="mb-8 ">EveryBotics Srl</p>
              </div>
            }
          />
        </div>
      </div>
    </main>
  );
};

export default PrivacyPolicy;
