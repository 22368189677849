import React, { useState } from "react";
import Navbar from "./scenes/navbar";
import { Screens, SelectedPage } from "./shared/types";
import "./App.css";
import Sidebar from "./scenes/sidebar";
import HomePage from "./scenes/pages/home";
import SocialBar from "./scenes/socialBar";
import Footer from "./scenes/footer";
import ScrollToTopButton from "./components/ScrollToTopButton";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Services from "./scenes/pages/services";
import Projects from "./scenes/pages/projects";
import TeamPage from "./scenes/pages/team/teamPage";
import AboutPage from "./scenes/pages/about";
import ContactPage from "./scenes/pages/contact";
import TeamMember from "./scenes/pages/team/teamMember";
import useMediaQuery from "./hooks/useMediaQuery";
import ServicesMobile from "./scenes/pages/services/indexMobile";
import ProjectMobile from "./scenes/pages/projects/indexMobile";
import PrivacyPolicy from "./scenes/pages/policy/privacyPolicy";

function App() {
  const [selectedPage, setSelectedPage] = useState<SelectedPage>(
    SelectedPage.Home
  );

  const [selectedDiv, setSelectedDiv] = useState<String>("home");
  const [previousDiv, setPreviousDiv] = useState<String>("home");
  const [overBar, setOverBar] = useState<boolean>(false);
  const [buttonHover, setButtonHover] = useState<boolean>(false);
  const [isMenuToggled, setIsMenuToggled] = useState<boolean>(false);

  const isAboveHugeScreen = useMediaQuery(Screens.Huge);
  const isAboveLargeScreen = useMediaQuery(Screens.Large);
  const isAboveMediumScreen = useMediaQuery(Screens.Medium);
  const isAboveSmallScreens = useMediaQuery(Screens.Small);

  function getPageDimens(): String {
    if (isAboveHugeScreen) {
      return " h-[calc(100vh-90px)]";
    } else if (isAboveLargeScreen) {
      return " h-[calc(100vh-90px)]";
    } else if (isAboveMediumScreen || isAboveSmallScreens) {
      return " h-[calc(100vh-60px)]";
    } else {
      return " h-[calc(100vh-60px)]";
    }
  }

  return (
    <div className={`scrollbar-hidden fixed top-0  w-full ${getPageDimens()}`}>
      <div className="flex h-screen w-full select-none flex-col ">
        <div className={` z-50 bg-white`}>
          <Navbar
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            isMenuToggled={isMenuToggled}
            setIsMenuToggled={setIsMenuToggled}
          />
        </div>
        <div className="flex flex-1 select-none flex-row overflow-y-hidden">
          <Routes>
            <Route
              path="/"
              element={
                <HomePage
                  buttonHover={buttonHover}
                  setButtonHover={setButtonHover}
                  isMenuToggled={isMenuToggled}
                  setIsMenuToggled={setIsMenuToggled}
                  selectedDiv={selectedDiv}
                  setSelectedDiv={setSelectedDiv}
                  previousDiv={previousDiv}
                  setPreviousDiv={setPreviousDiv}
                  setSelectedPage={setSelectedPage}
                />
              }
            />
            <Route
              path="/"
              element={
                <HomePage
                  buttonHover={buttonHover}
                  setButtonHover={setButtonHover}
                  isMenuToggled={isMenuToggled}
                  setIsMenuToggled={setIsMenuToggled}
                  selectedDiv={selectedDiv}
                  setSelectedDiv={setSelectedDiv}
                  previousDiv={previousDiv}
                  setPreviousDiv={setPreviousDiv}
                  setSelectedPage={setSelectedPage}
                />
              }
            />
            <Route
              path="/home"
              element={
                <HomePage
                  buttonHover={buttonHover}
                  setButtonHover={setButtonHover}
                  isMenuToggled={isMenuToggled}
                  setIsMenuToggled={setIsMenuToggled}
                  selectedDiv={selectedDiv}
                  setSelectedDiv={setSelectedDiv}
                  previousDiv={previousDiv}
                  setPreviousDiv={setPreviousDiv}
                  setSelectedPage={setSelectedPage}
                />
              }
            />
            {isAboveSmallScreens ? (
              <Route
                path="/services"
                element={
                  <Services
                    isMenuToggled={isMenuToggled}
                    setIsMenuToggled={setIsMenuToggled}
                    selectedDiv={selectedDiv}
                    setSelectedDiv={setSelectedDiv}
                    setSelectedPage={setSelectedPage}
                  />
                }
              />
            ) : (
              <Route
                path="/services"
                element={
                  <Services
                    isMenuToggled={isMenuToggled}
                    setIsMenuToggled={setIsMenuToggled}
                    selectedDiv={selectedDiv}
                    setSelectedDiv={setSelectedDiv}
                    setSelectedPage={setSelectedPage}
                  />
                }
              />
            )}{" "}
            {isAboveSmallScreens ? (
              <Route
                path="/projects"
                element={
                  <Projects
                    isMenuToggled={isMenuToggled}
                    setIsMenuToggled={setIsMenuToggled}
                    selectedDiv={selectedDiv}
                    setSelectedDiv={setSelectedDiv}
                    setSelectedPage={setSelectedPage}
                  />
                }
              />
            ) : (
              <Route
                path="/projects"
                element={
                  <Projects
                    isMenuToggled={isMenuToggled}
                    setIsMenuToggled={setIsMenuToggled}
                    selectedDiv={selectedDiv}
                    setSelectedDiv={setSelectedDiv}
                    setSelectedPage={setSelectedPage}
                  />
                }
              />
            )}
            <Route
              path="/about"
              element={
                <AboutPage
                  isMenuToggled={isMenuToggled}
                  setIsMenuToggled={setIsMenuToggled}
                  selectedDiv={selectedDiv}
                  setSelectedDiv={setSelectedDiv}
                  setSelectedPage={setSelectedPage}
                />
              }
            />
            <Route
              path="/contact"
              element={
                <ContactPage
                  isMenuToggled={isMenuToggled}
                  setIsMenuToggled={setIsMenuToggled}
                  selectedDiv={selectedDiv}
                  setSelectedDiv={setSelectedDiv}
                />
              }
            />
            <Route
              path="/privacyPolicy"
              element={
                <PrivacyPolicy
                  isMenuToggled={isMenuToggled}
                  setIsMenuToggled={setIsMenuToggled}
                  selectedDiv={selectedDiv}
                  setSelectedDiv={setSelectedDiv}
                />
              }
            />
          </Routes>
          {isAboveSmallScreens && (
            <Sidebar
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
              overBar={overBar}
              setOverBar={setOverBar}
            />
          )}
          {isAboveSmallScreens && <SocialBar />}
        </div>
        {isAboveSmallScreens && (
          <Footer selectedDiv={selectedDiv} setSelectedPage={setSelectedPage} />
        )}
      </div>
    </div>
  );
}

export default App;
