import AnchorLink from "react-anchor-link-smooth-scroll";
import { Screens, SelectedPage } from "../../shared/types";
import AnimatedOverline from "../../components/AnimatedOverline";
import AnimatedWordBar from "../../components/AnimatedWordBar";

import { NavLink } from "react-router-dom";
import useMediaQuery from "../../hooks/useMediaQuery";
import { useTranslation } from "react-i18next";
type Props = {
  page: string;
  selectedPage: SelectedPage;
  setSelectedPage: (value: SelectedPage) => void;
  setIsMenuToggled: (value: boolean) => void;
};

const Link = ({
  page,
  selectedPage,
  setSelectedPage,
  setIsMenuToggled,
}: Props) => {
  const lowerCasePage = page
    .toLocaleLowerCase()
    .replace(/ /g, "") as SelectedPage;

  const isAboveHugeScreen = useMediaQuery(Screens.Huge);
  const isAboveLargeScreen = useMediaQuery(Screens.Large);
  const isAboveMediumScreen = useMediaQuery(Screens.Medium);
  const isAboveSmallScreen = useMediaQuery(Screens.Small);

  const { t, i18n } = useTranslation();

  function getTitleDimens(): String {
    if (isAboveHugeScreen) {
      return "text-8xl";
    } else if (isAboveLargeScreen) {
      return "text-8xl";
    } else if (isAboveMediumScreen) {
      return "text-8xl";
    } else {
      return "text-5xl";
    }
  }

  return (
    <NavLink
      className={`
      ${getTitleDimens()} font-extrabold text-cobalt  
        `}
      to={`/${lowerCasePage}`}
      onClick={() => {
        setSelectedPage(lowerCasePage);
        setIsMenuToggled(false);
      }}
    >
      <AnimatedWordBar clicked={selectedPage === lowerCasePage}>
        {t(page)}
      </AnimatedWordBar>
    </NavLink>
  );
};

export default Link;
