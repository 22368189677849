import { useCallback, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import ContentPage from "./page";
import DiVito from "../../../assets/divitoBlack.png";
import Gillini from "../../../assets/gilliniBlack.png";
import DiLillo from "../../../assets/dililloBlack.png";
import Delia from "../../../assets/deliaBlack.png";
import Marino from "../../../assets/marinoBlack.png";
import Arrichiello from "../../../assets/arrichielloBlack.png";
import Antonelli from "../../../assets/antonelliBlack.png";
import useMediaQuery from "../../../hooks/useMediaQuery";
import { Screens } from "../../../shared/types";
import { useTranslation } from "react-i18next";

type Props = {
  name: string;
  index: number;
  setModalIsOpen: (value: boolean) => void;
};

const TeamMember = ({ name, index, setModalIsOpen }: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  const location = useLocation();

  const isAboveHugeScreen = useMediaQuery(Screens.Huge);
  const isAboveLargeScreen = useMediaQuery(Screens.Large);
  const isAboveMediumScreen = useMediaQuery(Screens.Medium);
  const isAboveSmallScreen = useMediaQuery(Screens.Small);

  function selectImage(): string {
    switch (name) {
      case "danieledivito":
        return DiVito;

      case "giuseppegillini":
        return Gillini;

      case "paolodilillo":
        return DiLillo;

      case "cirod'elia":
        return Delia;

      case "alessandromarino":
        return Marino;

      case "filippoarrichiello":
        return Arrichiello;

      case "gianlucaantonelli":
        return Antonelli;
    }
    return "";
  }

  function selectName(): string {
    switch (name) {
      case "danieledivito":
        return "Daniele Di Vito";

      case "giuseppegillini":
        return "Giuseppe Gillini";

      case "paolodilillo":
        return "Paolo Di Lillo";

      case "cirod'elia":
        return "Ciro D'Elia";

      case "alessandromarino":
        return "Alessandro Marino";

      case "filippoarrichiello":
        return "Filippo Arrichiello";

      case "gianlucaantonelli":
        return "Gianluca Antonelli";
    }
    return "";
  }

  function selectColor(): string {
    return index % 2 == 0 ? "text-cobalt" : "text-peach";
  }

  function getTitleString(): String {
    if (isAboveHugeScreen) {
      return "text-4xl";
    } else if (isAboveLargeScreen) {
      return "text-4xl";
    } else if (isAboveMediumScreen) {
      return "text-2xl";
    } else if (isAboveSmallScreen) {
      return "text-xl";
    } else {
      return "text-xl";
    }
  }

  const { t, i18n } = useTranslation();

  function selectPageContent(): JSX.Element {
    switch (name) {
      case "danieledivito":
        return (
          <div className="">
            <p
              className={`mb-4  ${getTitleString()} font-semibold ${selectColor()} `}
            >
              {" "}
              {t("role")}
            </p>{" "}
            <p className="mb-8 text-white">{t("chiefExecutiveOfficer")}</p>
            <p
              className={`mb-4   ${getTitleString()} font-semibold ${selectColor()} `}
            >
              {" "}
              {t("teamAbout")} Daniele
            </p>{" "}
            <p className="mb-8 text-white">
              {t("teamDaniele1")}{" "}
              <span className={` font-semibold ${selectColor()}`}>
                {t("teamDaniele2")}
              </span>{" "}
              {t("teamDaniele3")}{" "}
              <span className={` font-semibold ${selectColor()}`}>
                motion planning
              </span>
              ,{" "}
              <span className={` font-semibold ${selectColor()}`}>
                {t("teamDaniele4")}
              </span>{" "}
              {t("teamDaniele5")}{" "}
              <span className={` font-semibold ${selectColor()}`}>
                {t("teamDaniele6")}{" "}
              </span>{" "}
              {t("teamDaniele7")}{" "}
              <span className={` font-semibold ${selectColor()}`}>
                {t("teamDaniele8")}{" "}
              </span>{" "}
              {t("teamDaniele9")}{" "}
            </p>
          </div>
        );

      case "giuseppegillini":
        return (
          <div>
            <p
              className={`mb-4   ${getTitleString()} font-semibold ${selectColor()} `}
            >
              {" "}
              {t("role")}
            </p>{" "}
            <p className="mb-8 text-white">{t("chiefTechnologyOfficer")}</p>
            <p
              className={`mb-4   ${getTitleString()} font-semibold ${selectColor()} `}
            >
              {" "}
              {t("teamAbout")} Giuseppe
            </p>{" "}
            <p className="mb-8 text-white">
              <span className={` font-semibold ${selectColor()}`}>C.T.O.</span>{" "}
              {t("teamGiuseppe1")}{" "}
              <span className={` font-semibold ${selectColor()}`}>
                {t("teamGiuseppe2")}{" "}
              </span>
              {t("teamGiuseppe3")}{" "}
              <span className={` font-semibold ${selectColor()}`}>
                {" "}
                {t("teamDaniele2")}
              </span>{" "}
              {t("teamGiuseppe4")}{" "}
              <span className={` font-semibold ${selectColor()}`}>
                {t("teamGiuseppe5")}{" "}
              </span>{" "}
              {t("teamGiuseppe6")}{" "}
              <span className={` font-semibold ${selectColor()}`}>
                {t("teamGiuseppe7")}{" "}
              </span>{" "}
              {t("teamGiuseppe8")}
            </p>
          </div>
        );

      case "paolodilillo":
        return (
          <div>
            <p
              className={`mb-4   ${getTitleString()} font-semibold ${selectColor()} `}
            >
              {" "}
              {t("role")}
            </p>{" "}
            <p className="mb-8 text-white">{t("chairman")}</p>
            <p
              className={`mb-4   ${getTitleString()} font-semibold ${selectColor()} `}
            >
              {" "}
              {t("teamAbout")} Paolo
            </p>{" "}
            <p className="mb-8 text-white">
              {t("teamPaolo1")}{" "}
              <span className={` font-semibold ${selectColor()}`}>
                {t("teamAbout")}
              </span>{" "}
              {t("teamPaolo2")}{" "}
              <span className={` font-semibold ${selectColor()}`}>
                {t("teamPaolo3")}
              </span>{" "}
              {t("teamPaolo4")}{" "}
              <span className={` font-semibold ${selectColor()}`}>
                {t("teamPaolo5")}{" "}
              </span>
              {t("teamPaolo6")}
            </p>{" "}
          </div>
        );

      case "cirod'elia":
        return (
          <div>
            <p
              className={`mb-4   ${getTitleString()} font-semibold ${selectColor()} `}
            >
              {" "}
              {t("role")}
            </p>{" "}
            <p className="mb-8 text-white">{t("adCom")}</p>
            <p
              className={`mb-4   ${getTitleString()} font-semibold ${selectColor()} `}
            >
              {" "}
              {t("teamAbout")} Ciro
            </p>{" "}
            <p className="mb-8 text-white">
              {t("teamCiro1")}{" "}
              <span className={` font-semibold ${selectColor()}`}>
                {t("teamCiro2")}
              </span>{" "}
              {t("teamCiro3")}{" "}
              <span className={` font-semibold ${selectColor()}`}>
                {t("teamCiro4")}
              </span>
              {t("teamCiro5")}{" "}
              <span className={` font-semibold ${selectColor()}`}>
                {t("teamCiro6")}{" "}
              </span>
              {t("and")}{" "}
              <span className={` font-semibold ${selectColor()}`}>
                {t("teamCiro7")}
              </span>
              ,{" "}
              <span className={` font-semibold ${selectColor()}`}>
                {t("teamCiro8")}{" "}
              </span>
              {t("teamCiro9")}{" "}
              <span className={` font-semibold ${selectColor()}`}>
                {t("teamCiro10")}{" "}
              </span>
              {t("teamCiro11")}
            </p>{" "}
          </div>
        );

      case "alessandromarino":
        return (
          <div>
            <p
              className={`mb-4   ${getTitleString()} font-semibold ${selectColor()} `}
            >
              {" "}
              {t("role")}
            </p>{" "}
            <p className="mb-8 text-white">{t("adCom")}</p>
            <p
              className={`mb-4   ${getTitleString()} font-semibold ${selectColor()} `}
            >
              {" "}
              {t("teamAbout")} Alessandro
            </p>{" "}
            <p className="mb-8 text-white">
              {t("teamAlessandro1")}{" "}
              <span className={` font-semibold ${selectColor()}`}>
                {t("teamAlessandro2")}{" "}
              </span>
              {t("teamAlessandro3")}{" "}
              <span className={` font-semibold ${selectColor()}`}>
                {t("teamDaniele2")}{" "}
              </span>
              {t("teamAlessandro4")}{" "}
              <span className={` font-semibold ${selectColor()}`}>
                {t("teamAlessandro5")}{" "}
              </span>
              {t("teamAlessandro6")}{" "}
              <span className={` font-semibold ${selectColor()}`}>
                CANOPIES
              </span>
              –A Collaborative Paradigm for Human Workers and Multi-Robot Teams
              in Precision Agriculture Systems.
            </p>
          </div>
        );

      case "filippoarrichiello":
        return (
          <div>
            <p
              className={`mb-4   ${getTitleString()} font-semibold ${selectColor()} `}
            >
              {" "}
              {t("role")}
            </p>{" "}
            <p className="mb-8 text-white">{t("viceChairman")}</p>
            <p
              className={`mb-4   ${getTitleString()} font-semibold ${selectColor()} `}
            >
              {" "}
              {t("teamAbout")} Filippo
            </p>{" "}
            <p className="mb-8 text-white">
              {t("teamFilippo1")}{" "}
              <span className={` font-semibold ${selectColor()}`}>
                {t("teamAlessandro5")}{" "}
              </span>
              {t("teamFilippo2")}{" "}
              <span className={` font-semibold ${selectColor()}`}>
                {t("roboticsManipulator")}{" "}
              </span>
              {t("and")}{" "}
              <span className={` font-semibold ${selectColor()}`}>
                {t("mobileRobotics")}{" "}
              </span>
              {t("teamFilippo3")}{" "}
              <span className={` font-semibold ${selectColor()}`}>
                {t("europeanProjects")}
              </span>
              .
            </p>
          </div>
        );

      case "gianlucaantonelli":
        return (
          <div>
            <p
              className={`mb-4   ${getTitleString()} font-semibold ${selectColor()} `}
            >
              {" "}
              {t("role")}
            </p>{" "}
            <p className="mb-8 text-white">{t("adCom")}</p>
            <p
              className={`mb-4   ${getTitleString()} font-semibold ${selectColor()} `}
            >
              {" "}
              {t("teamAbout")} Gianluca
            </p>{" "}
            <p className="mb-8 text-white">
              {t("teamGianluca1")}{" "}
              <span className={` font-semibold ${selectColor()}`}>
                {t("fullProfessor")}{" "}
              </span>
              {t("teamGianluca2")}{" "}
              <span className={` font-semibold ${selectColor()}`}>
                {t("teamGianluca3")}
              </span>{" "}
              ,{" "}
              <span className={` font-semibold ${selectColor()}`}>
                {t("teamGianluca4")}
              </span>
              , {t("and")}{" "}
              <span className={` font-semibold ${selectColor()}`}>
                {t("identification")}
              </span>
              {t("teamGianluca5")}{" "}
              <span className={` font-semibold ${selectColor()}`}>{t("author")}</span>{" "}
              {t("teamGianluca6")}
            </p>
          </div>
        );
    }

    return <div></div>;
  }

  return (
    <div
      id={name}
      style={{ padding: 0, margin: 0, boxSizing: "border-box" }}
      className="flex flex-col items-center justify-center bg-blue-black"
    >
      <ContentPage
        pageContent={selectPageContent()}
        index={index}
        pic={selectImage()}
        name={selectName()}
        setModalIsOpen={setModalIsOpen}
      />
    </div>
  );
};

export default TeamMember;
