import { useCallback, useRef } from "react";
import FirstPage from "../../../components/firstPage";
import ContentPage from "../../../components/page";
import ScrollToTopButton from "../../../components/ScrollToTopButton";
import Pic from "../../../assets/everybot.png";
import useMediaQuery from "../../../hooks/useMediaQuery";
import { Screens, SelectedPage } from "../../../shared/types";
import { useTranslation } from "react-i18next";
import VideoPlayer from "../../../components/VideoPlayer";
import Team from "../home/components/team";

type Props = {
  selectedDiv: String;
  setSelectedDiv: (value: String) => void;
  isMenuToggled: boolean;
  setIsMenuToggled: (value: boolean) => void;
  setSelectedPage: (value: SelectedPage) => void;
};

const About = ({
  selectedDiv,
  setSelectedDiv,
  isMenuToggled,
  setIsMenuToggled,
  setSelectedPage,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  const scrollToRef = () => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const isAboveHugeScreen = useMediaQuery(Screens.Huge);
  const isAboveLargeScreen = useMediaQuery(Screens.Large);
  const isAboveMediumScreen = useMediaQuery(Screens.Medium);
  const isAboveSmallScreen = useMediaQuery(Screens.Small);

  function getPageDimens(): String {
    if (isAboveHugeScreen) {
      return "h-[calc(100vh-180px)]]";
    } else if (isAboveLargeScreen) {
      return " h-[calc(100vh-180px)]";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return " h-[calc(100vh-120px)]";
    } else {
      return "h-[calc(100vh-120px)]]";
    }
  }

  const { t, i18n } = useTranslation();

  return (
    <main
      id="aboutPage"
      style={{ padding: 0, margin: 0, boxSizing: "border-box" }}
      className={`relative ${getPageDimens()} w-full snap-start overflow-hidden bg-cover bg-center bg-no-repeat p-[calc(60px)]`}
    >
      <div className="h-full w-full flex justify-center items-center">
        <VideoPlayer />
      </div>
      
    </main>
  );
};

export default About;
