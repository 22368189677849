import WhiteLogo from "../../assets/white_logo.png";
import WhiteWordLogo from "../../assets/white_word_logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import Link from "../menu/Link";
import { Screens, SelectedPage } from "../../shared/types";
import useMediaQuery from "../../hooks/useMediaQuery";
import { NavLink } from "react-router-dom";
import LanguageSwitcher from "../../components/languageSwitcher";

type Props = {
  isMenuToggled: boolean;
  selectedPage: SelectedPage;
  setSelectedPage: (value: SelectedPage) => void;
  style?: React.CSSProperties;
  setIsMenuToggled: (value: boolean) => void;
};

const MenuPage = ({
  isMenuToggled,
  selectedPage,
  setSelectedPage,
  style,
  setIsMenuToggled,
}: Props) => {
  const isAboveMediumScreens = useMediaQuery(Screens.Small);
  return (
    <div
      style={style}
      className={`fixed right-0 top-0  flex h-full w-full origin-top-right transform
                     bg-peach transition-all duration-500 ${
                       isMenuToggled ? "scale-100" : "scale-0"
                     }`}
    >
      {isAboveMediumScreens && (
        <div className="flex h-full w-1/4 flex-col items-center justify-between bg-cobalt p-16 ">
          <div className="w-1/2">
            <img alt="everybotics" src={WhiteWordLogo} />
          </div>

          <div className="w-2/3">
            <img alt="white_logo" src={WhiteLogo} />
          </div>

          <div className="flex flex-col items-center justify-between gap-8">
            <div className="flex items-center justify-between gap-4">
              <div className="flex aspect-square h-10 items-center justify-center bg-white">
                <FontAwesomeIcon color="#004aad" icon={faInstagram} size="xl" />
              </div>
              <div className="flex aspect-square h-10 items-end justify-center bg-white">
                <FontAwesomeIcon
                  color="#004aad"
                  icon={faFacebookF}
                  size="2xl"
                />
              </div>
              <div className="flex aspect-square h-10 items-center justify-center bg-white">
                <FontAwesomeIcon color="#004aad" icon={faYoutube} size="xl" />
              </div>
            </div>
            <div className="text-center text-2xl text-white">
              Via G. Di Biasio, 43 <br />
              Cassino (FR) - Italia
              <br />
              www.everybotics.it
            </div>
            <div className="text-center text-2xl text-white">
              P.IVA 03130710605 <br />
              SDI: SUBM7ON
            </div>
            <div className="flex items-center justify-center ">
              <LanguageSwitcher menu={true} bigScreen={true} />
            </div>
          </div>
        </div>
      )}
      <div
        className={` flex items-center justify-center ${
          isAboveMediumScreens ? "w-3/4" : "w-full flex-col"
        }`}
      >
        <div
          className={`flex flex-col  justify-center gap-3 ${
            isAboveMediumScreens ? "items-start" : "items-center"
          }`}
        >
          <Link
            page="HOME"
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            setIsMenuToggled={setIsMenuToggled}
          />
          <Link
            page="SERVICES"
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            setIsMenuToggled={setIsMenuToggled}
          />
          <Link
            page="PROJECTS"
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            setIsMenuToggled={setIsMenuToggled}
          />
          
          <Link
            page="ABOUT"
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            setIsMenuToggled={setIsMenuToggled}
          />
          <Link
            page="CONTACT"
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            setIsMenuToggled={setIsMenuToggled}
          />
        </div>
        {!isAboveMediumScreens && (
          <div className="flex flex-col items-center justify-center absolute bottom-14">
            <LanguageSwitcher menu={true} bigScreen={false}/>

            <div className=" flex flex-row items-center justify-center gap-4 text-center text-sm text-cobalt my-[calc(10px)]">
              <div>P.IVA 03130710605</div>{" "}
              <NavLink
                className={` text-xs}`}
                to={`/privacyPolicy`}
                onClick={() => {
                  setSelectedPage(SelectedPage.Privacy);
                  setIsMenuToggled(false);
                }}
              >
                <button>Privacy Policy</button>
              </NavLink>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MenuPage;
