import useMediaQuery from "../hooks/useMediaQuery";
import { Screens } from "../shared/types";

type Props = {
  pageContent: JSX.Element;
  pic: string;
};

const ContentPageMobile = ({ pageContent, pic }: Props) => {
  const isAboveHugeScreen = useMediaQuery(Screens.Huge);
  const isAboveLargeScreen = useMediaQuery(Screens.Large);
  const isAboveMediumScreen = useMediaQuery(Screens.Medium);
  const isAboveSmallScreen = useMediaQuery(Screens.Small);

  function getTextDimens(): String {
    if (isAboveHugeScreen) {
      return "text-2xl";
    } else if (isAboveLargeScreen) {
      return "text-2xl";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "text-base";
    } else {
      return "text-base";
    }
  }

  function getGridDimens(): String {
    if (isAboveHugeScreen) {
      return "w-[calc(100%-120px)]  gap-[calc(120px)]";
    } else if (isAboveLargeScreen) {
      return "w-[calc(100%)]  gap-[calc(120px) ";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "w-[calc(100%-60px)]  gap-[calc(60px)]";
    } else {
      return "w-[calc(100%-60px)]  gap-[calc(60px)]";
    }
  }

  function getArticleWidth(): String {
    if (isAboveHugeScreen) {
      return "w-[calc(100%-60px)] ";
    } else if (isAboveLargeScreen) {
      return "w-[calc(100%-120px)] px-[calc(120px)]";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "w-[calc(100%-30px)] ";
    } else {
      return "w-full ";
    }
  }
  function getSpaceAndMargin(): String {
    if (isAboveHugeScreen) {
      return " gap-6 pl-[calc(120px)] pt-[calc(60px)]";
    } else if (isAboveLargeScreen) {
      return " gap-6 px-[calc(120px)] pt-[calc(60px)]";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return " gap-4 pl-[calc(60px)] pt-[calc(30px)]";
    } else {
      return "  px-[calc(60px)] pt-[calc(30px)]";
    }
  }

  function getSpaceAndMargin2(): String {
    if (isAboveHugeScreen) {
      return " gap-6 pl-[calc(60px)] pt-[calc(60px)]";
    } else if (isAboveLargeScreen) {
      return " gap-6 pl-[calc(60px)] pt-[calc(60px)]";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return " gap-4 pl-[calc(60px)] pt-[calc(30px)]";
    } else {
      return " px-[calc(60px)] pt-[calc(30px)]";
    }
  }

  return (
    <div className="relative flex  h-[calc(100vh-120px)] w-full snap-start items-start justify-center overflow-auto ">
      {" "}
      <div className={`text-justify ${getArticleWidth()}`}>
        <div
          className={`flex h-full w-full flex-col justify-center ${getSpaceAndMargin()}`}
        ></div>
        <div
          className={`col-span-1 ${getTextDimens()} ${getSpaceAndMargin2()}`}
        >
          {pageContent}
        </div>
      </div>
    </div>
  );
};

export default ContentPageMobile;
