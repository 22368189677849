import { useState } from "react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/solid";
import Banner from "../../assets/banner.png";
import LogoHorizontal from "../../assets/logo_h.png";
import Link from "./Link";
import { SelectedPage } from "../../shared/types";
import useMediaQuery from "../../hooks/useMediaQuery";
import HamburgerMenu from "../../components/HamburgerMenu";
import MenuPage from "../menu";

type Props = {
  selectedPage: SelectedPage;
  setSelectedPage: (value: SelectedPage) => void;
};

const Items = ({ selectedPage, setSelectedPage }: Props) => {
  return (
    <div className=" absolute right-[calc(59px)] top-6  flex flex-col items-end justify-between gap-0 ">
      <Link
        page="HOME"
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}
        itemNumber={0}
      />
      <Link
        page="SERVICES"
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}
        itemNumber={1}
      />
      <Link
        page="PROJECTS"
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}
        itemNumber={2}
      />
      <Link
        page="TEAM"
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}
        itemNumber={3}
      />
      <Link
        page="ABOUT"
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}
        itemNumber={4}
      />
      <Link
        page="CONTACT"
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}
        itemNumber={5}
      />
    </div>
  );
};

export default Items;
