import React, { useEffect, useState } from "react";
import useMediaQuery from "../../hooks/useMediaQuery";
import { Screens } from "../../shared/types";

interface BallProps {
  onCollision: (
    collision: "brick" | "bottom" | "paddle" | "top" | "horizontal"
  ) => void;
  initialPosition: { x: number; y: number; width: number; height: number };
  setGameWon: (value: boolean) => void;
  vX: number;
  vY: number;
}

const Ball: React.FC<BallProps> = ({
  onCollision,
  initialPosition,
  setGameWon,
  vX,
  vY,
}) => {
  const [ballX, setBallX] = useState(initialPosition.x);
  const [ballY, setBallY] = useState(initialPosition.y + 10);

  const isAboveHugeScreen = useMediaQuery(Screens.Huge);
  const isAboveLargeScreen = useMediaQuery(Screens.Large);
  const isAboveMediumScreen = useMediaQuery(Screens.Medium);
  const isAboveSmallScreen = useMediaQuery(Screens.Small);

  var [velocityX, setVelocityX] = useState(vX);
  var [velocityY, setVelocityY] = useState(vY);

  useEffect(() => {
    const interval = setInterval(() => {
      setBallX((prevX) => prevX + velocityX);
      setBallY((prevY) => prevY + velocityY);
    }, 10);

    return () => clearInterval(interval);
  }, [velocityX, velocityY]);

  useEffect(() => {
    handleCollision();
  }, [ballX, ballY]);

  useEffect(() => {
    setBallX(initialPosition.x);
    setBallY(initialPosition.y - 60);
  }, []);

  const handleCollision = () => {
    // Check if the ball has collided with the top of the container
    const container = document.querySelector(".gameBoard");
    const ball = document.querySelector(".ball");
    if (container && ball) {
      const containerRect = container.getBoundingClientRect();
      const ballRect = ball.getBoundingClientRect();

      if (ballRect.top <= containerRect.top +32) {
        onCollision("top");
        setVelocityY(-velocityY); // Set the vertical velocity to a positive value to continue moving the ball downward
      }

      if (ballRect.bottom >= containerRect.bottom ) {
        // Call the onCollision callback with "bottom" as the argument if the ball has collided with the bottom of the container
        onCollision("bottom");

        setBallX(initialPosition.x);
        setBallY(initialPosition.y - 60);
        setVelocityY(-velocityY);
      }

      if (
        ballRect.left <= containerRect.left + 32||
        ballRect.right >= containerRect.right - 32
      ) {
        // Call the onCollision callback with "horizontal" as the argument if the ball has collided with the left or right side of the container
        onCollision("horizontal");
        setVelocityX(-velocityX); // Reverse the horizontal velocity to move the ball in the opposite direction
      }
    }

    // Check if the ball has collided with the paddle
    const paddle = document.querySelector(".paddle");
    if (paddle && ball) {
      const paddleRect = paddle.getBoundingClientRect();
      const ballRect = ball.getBoundingClientRect();

      if (
        ballRect.bottom >= paddleRect.top &&
        ballRect.left >= paddleRect.left &&
        ballRect.right <= paddleRect.right
      ) {
        onCollision("paddle");
      
        // Calculate the distance between the ball's center and the paddle's center
        const ballCenterX = ballRect.left + ballRect.width / 2;
        const paddleCenterX = paddleRect.left + paddleRect.width / 2;
        const distanceFromCenter = ballCenterX - paddleCenterX;
      
        // Adjust the vertical velocity based on the distance from the center
        // This will make the ball move more horizontally when it hits the corner
        setVelocityY(-velocityY);
        setVelocityX(velocityX + distanceFromCenter * 0.01);
      }
    }

    const bricks = document.querySelectorAll(".brick");

    if (bricks.length === 0) {
      setGameWon(true);
    }
    if (bricks && ball) {
      for (let i = 0; i < bricks.length; i++) {
        const brick = bricks[i];
        const brickRect = brick.getBoundingClientRect();
        const ballRect = ball.getBoundingClientRect();

        // Calculate the minimum distance between the edges of the ball and the edges of the brick for a collision to occur
        const minDistanceX = ballRect.width / 2 + brickRect.width / 2;
        const minDistanceY = ballRect.height / 2 + brickRect.height / 2;

        // Calculate the distance between the centers of the ball and the brick in the X and Y directions
        const distanceX = Math.abs(
          ballRect.left +
            ballRect.width / 2 -
            brickRect.left -
            brickRect.width / 2
        );
        const distanceY = Math.abs(
          ballRect.top +
            ballRect.height / 2 -
            brickRect.top -
            brickRect.height / 2
        );

        if (
          ballRect &&
          distanceX <= minDistanceX &&
          distanceY <= minDistanceY
        ) {
          // Call the onCollision callback with "brick" as the argument if the ball has collided with a brick
          onCollision("brick");

          // Replace the brick with an empty div with the same dimensions
          const emptyBrick = document.createElement("div");
          emptyBrick.style.width = `${brickRect.width}px`;
          emptyBrick.style.height = `${brickRect.height}px`;
          emptyBrick.style.backgroundColor = "transparent";
          brick.parentNode?.insertBefore(emptyBrick, brick);
          brick.remove();

          // Reverse the vertical velocity to move the ball upward
          setVelocityY(-velocityY);

          // Reverse the horizontal velocity to change the ball's direction
          if (
            ballRect.left > brickRect.left &&
            ballRect.right < brickRect.right
          ) {
            setVelocityX(-velocityX);
          } else if (ballRect.left < brickRect.left) {
            setVelocityX(Math.abs(velocityX));
          } else {
            setVelocityX(-Math.abs(velocityX));
          }

          // Exit the loop after the first brick is removed
          break;
        }
      }
    }
  };
  return (
    <div
      className="ball absolute rounded-full bg-white"
      style={{
        left: ballX,
        top: ballY,
        width: "20px",
        height: "20px",
      }}
    ></div>
  );
};

export default Ball;
