import useMediaQuery from "../hooks/useMediaQuery";

export enum SelectedPage {
  Home = "home",
  Services = "services",
  Projects = "projects",
  Team = "team",
  About = "about",
  Contact = "contact",
  Privacy = "privacyPolicy"
}

export enum SelectedSocial {
  Facebook = "facebook",
  Instagram = "instagram",
  Youtube = "youtube",
}

export enum SelectedSnap {
  Home = "home",
  About = "about",
  Services = "services",
  SpinOff = "spinoff",
  Team = "game",
  Contact = "contact",
  FindUs = "findus",
}

export enum SelectedService {
  Coding = "coding",
  AI = "a.i.",
  Robotics = "robotics",
  WebSites = "websites",
  Cybersecurity = "cybersecurity",
  Services = "services",
}

export const services: string[] = [
  SelectedService.Coding,
  SelectedService.AI,
  SelectedService.Robotics,
  SelectedService.WebSites,
  SelectedService.Cybersecurity,
  SelectedService.Services,
];

export const marker = {
  address: "Via Gaetano di Biasio, 43, 03043 Cassino FR",
  lat: 41.487694,
  lng: 13.825658,
};

export enum Screens {
  Huge = "(min-width: 2550px) and (min-height: 1430)",
  Large = "(min-width: 2210px) and (min-height: 1070px)",
  Medium = "(min-width: 1366px) and (min-height: 768px)",
  Small = "(min-width: 1220px) and (min-height: 650px)",
  TabletSize = "(min-width: 601px) and (min-height: 100px)",
  PhoneSize = "(min-width: 600px) and (min-height: 560px)",
}


