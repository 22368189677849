import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { useState } from "react";
import useMediaQuery from "../../hooks/useMediaQuery";
import { Screens } from "../../shared/types";

type Props = {};

const SocialBar = ({}: Props) => {
  const isAboveHugeScreen = useMediaQuery(Screens.Huge);
  const isAboveLargeScreen = useMediaQuery(Screens.Large);
  const isAboveMediumScreen = useMediaQuery(Screens.Medium);
  const isAboveSmallScreens = useMediaQuery(Screens.Small);


  function getWidth(): String {
    if (isAboveHugeScreen) {
      return " w-whiteBorderXL";
    } else if (isAboveLargeScreen) {
      return " w-whiteBorderL";
    } else if (isAboveMediumScreen || isAboveSmallScreens) {
      return "w-whiteBorderM";
    } else {
      return " w-whiteBorderS";
    }
  }

  function getHeight(): String {
    if (isAboveHugeScreen) {
      return " h-[calc(100vh-90px-90px)] text-lg";
    } else if (isAboveLargeScreen) {
      return "h-[calc(100vh-90px-90px)] text-lg";
    } else if (isAboveMediumScreen || isAboveSmallScreens) {
      return "h-[calc(100vh-60px-60px)] text-base";
    } else {
      return "h-[calc(100vh-60px-60px)] text-base";
    }
  }

  function getIconHeight(): String {
    if (isAboveHugeScreen) {
      return "h-10";
    } else if (isAboveLargeScreen) {
      return "h-10";
    } else if (isAboveMediumScreen || isAboveSmallScreens) {
      return "h-7";
    } else {
      return "h-7  ";
    }
  }

  return (
    <aside
      className={`order-first flex flex-col items-center justify-center ${getHeight()} ${getWidth()}`}
    >
      <div className="flex flex-col items-center justify-between gap-3 ">
        <div
          className={`transition-width flex aspect-square items-center ${getIconHeight()} justify-center bg-cobalt duration-500 ease-in-out hover:scale-125`}
        >
          <a
            href="https://www.instagram.com/everybotics/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon color="#ffffff" icon={faInstagram} size="lg" />
          </a>
        </div>
        <div
          className={`transition-width flex aspect-square ${getIconHeight()} items-end justify-center bg-cobalt duration-500 ease-in-out hover:scale-125`}
        >
          <a
            href="https://www.facebook.com/everybotics"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon color="#ffffff" icon={faFacebookF} size="xl" />
          </a>
        </div>
        <div
          className={`transition-width flex aspect-square ${getIconHeight()} items-center justify-center bg-cobalt duration-500 ease-in-out hover:scale-125`}
        >
          <a
            href="https://www.youtube.com/@lairobotics"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon color="#ffffff" icon={faYoutube} size="sm" />
          </a>
        </div>
      </div>
    </aside>
  );
};

export default SocialBar;
