import { useCallback, useRef } from "react";
import FirstPage from "../../../components/firstPage";
import ContentPage from "../../../components/page";
import ScrollToTopButton from "../../../components/ScrollToTopButton";
import Pic from "../../../assets/everybot.png";
import Team from "../home/components/team";
import About from "./about";
import { Screens, SelectedPage } from "../../../shared/types";
import useMediaQuery from "../../../hooks/useMediaQuery";
import AboutMobile from "./aboutMobile";
import TeamMobile from "../home/mobile/teamMobile";
import TeamMobile2 from "../home/mobile/teamMobile2";
import TeamMobile3 from "../home/mobile/teamMobile3";
import TeamMobile4 from "../home/mobile/teamMobile4";
import { Certificate } from "crypto";
import Certificates from "./cartificates";

type Props = {
  selectedDiv: String;
  setSelectedDiv: (value: String) => void;
  isMenuToggled: boolean;
  setIsMenuToggled: (value: boolean) => void;
  setSelectedPage(value: SelectedPage): void;
};

const AboutPage = ({
  selectedDiv,
  setSelectedDiv,
  isMenuToggled,
  setIsMenuToggled,
  setSelectedPage,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  const scrollToRef = () => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const isAboveMediumScreens = useMediaQuery(Screens.Small);

  return (
    <main
      id="projects"
      style={{ padding: 0, margin: 0, boxSizing: "border-box" }}
      className={`no-scrollbar flex-1  ${
        isAboveMediumScreens ? "snap-y snap-mandatory" : ""
      }     overflow-y-auto overflow-x-hidden`}
    >
      <div
        onMouseEnter={() => {
          setSelectedDiv("aboutPage");
        }}
        id="aboutPage"
      >
        {isAboveMediumScreens ? (
          <About
            selectedDiv={selectedDiv}
            isMenuToggled={isMenuToggled}
            setIsMenuToggled={setIsMenuToggled}
            setSelectedDiv={setSelectedDiv}
            setSelectedPage={setSelectedPage}
          />
        ) : (
          <AboutMobile
            selectedDiv={selectedDiv}
            isMenuToggled={isMenuToggled}
            setIsMenuToggled={setIsMenuToggled}
            setSelectedDiv={setSelectedDiv}
          />
        )}

        {isAboveMediumScreens && <Team setSelectedPage={setSelectedPage} />}

        {!isAboveMediumScreens && (
          <TeamMobile setSelectedPage={setSelectedPage} />
        )}
        {!isAboveMediumScreens && (
          <TeamMobile2 setSelectedPage={setSelectedPage} />
        )}
        {!isAboveMediumScreens && (
          <TeamMobile3 setSelectedPage={setSelectedPage} />
        )}
        {!isAboveMediumScreens && (
          <TeamMobile4 setSelectedPage={setSelectedPage} />
        )}

          <Certificates
            selectedDiv={selectedDiv}
            isMenuToggled={isMenuToggled}
            setIsMenuToggled={setIsMenuToggled}
            setSelectedDiv={setSelectedDiv}
            setSelectedPage={setSelectedPage}
          />
      </div>
    </main>
  );
};

export default AboutPage;
