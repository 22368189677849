import BannerImage from "../../../../assets/banner.png";
import MouseParticles from "../../../../components/MouseParticles";
import YellowArrow from "../../../../assets/vertical_line_arrow.png";
import BannerSpinOff from "../../../../assets/banner_spinoff.png";
import BannerSpinOffITA from "../../../../assets/banner_spinoff_ita.png";
import { useEffect, useRef, useState } from "react";
import BannerLight from "../../../../assets/banner_light.png";
import MouseCircleEffect from "../../../../components/CircleEffect";
import useMediaQuery from "../../../../hooks/useMediaQuery";
import { Screens } from "../../../../shared/types";
import { useTranslation } from "react-i18next";



type Props = {};

const SpinOff = ({}: Props) => {
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      setCursorPosition({ x: event.clientX, y: event.clientY });
    };

    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);


  const isAboveHugeScreen = useMediaQuery(Screens.Huge);
  const isAboveLargeScreen = useMediaQuery(Screens.Large);
  const isAboveMediumScreen = useMediaQuery(Screens.Medium);
  const isAboveSmallScreen = useMediaQuery(Screens.Small);

  function getPageDimens(): String {
    if (isAboveHugeScreen) {
      return "h-[calc(100vh-180px)]]";
    } else if (isAboveLargeScreen) {
      return " h-[calc(100vh-180px)]";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return " h-[calc(100vh-120px)]";
    } else {
      return "h-[calc(100vh-120px)]]";
    }
  }
  
  const {t, i18n} = useTranslation();

  return (
    <div
      className={`relative ${getPageDimens()} w-full snap-start overflow-hidden bg-cover bg-center bg-no-repeat`}
      id="spinoff"
      style={{
        backgroundImage: `${i18n.language == "it" ? `url(${BannerSpinOffITA})` : `url(${BannerSpinOff})`}`,
      }}
    >
      <div
        className="flashing relative h-full w-full overflow-hidden bg-cover bg-center px-40 py-6"
        id="light"
      >
        <MouseCircleEffect />
      </div>
    </div>
  );
};

export default SpinOff;
