import { useEffect, useState } from "react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/solid";
import Banner from "../../assets/banner.png";
import LogoHorizontal from "../../assets/logo_h.png";
import LinkItem from "./Link";
import { Screens, SelectedPage } from "../../shared/types";
import useMediaQuery from "../../hooks/useMediaQuery";
import HamburgerMenu from "../../components/HamburgerMenu";
import MenuPage from "../menu";

type Props = {
  selectedPage: SelectedPage;
  setSelectedPage: (value: SelectedPage) => void;
  isMenuToggled: boolean;
  setIsMenuToggled: (value: boolean) => void;
};

const Navbar = ({
  selectedPage,
  setSelectedPage,
  isMenuToggled,
  setIsMenuToggled,
}: Props) => {
  const flexBetween = "flex items-center justify-between";
  const isAboveSmallScreens = useMediaQuery(Screens.Small);

  const isAboveHugeScreen = useMediaQuery(Screens.Huge);
  const isAboveLargeScreen = useMediaQuery(Screens.Large);
  const isAboveMediumScreen = useMediaQuery(Screens.Medium);

  function getHeight(): String {
    if (isAboveHugeScreen) {
      return " h-whiteBorderXL";
    } else if (isAboveLargeScreen) {
      return "h-whiteBorderL";
    } else if (isAboveMediumScreen || isAboveSmallScreens) {
      return "h-whiteBorderM";
    } else {
      return "h-whiteBorderS";
    }
  }

  function getSpacing(): String {
    if (isAboveHugeScreen) {
      return "  mx-whiteSpacingXL";
    } else if (isAboveLargeScreen) {
      return " mx-whiteSpacingL";
    } else if (isAboveMediumScreen || isAboveSmallScreens) {
      return " mx-whiteSpacingM";
    } else {
      return " mx-whiteSpacingXS";
    }
  }

  function getTextDimen(): String {
    if (isAboveHugeScreen) {
      return "  text-lg";
    } else if (isAboveLargeScreen) {
      return " text-lg";
    } else if (isAboveMediumScreen || isAboveSmallScreens) {
      return "text-sm";
    } else {
      return " text-sm";
    }
  }

  function getBannerDimens(): String {
    if (isAboveHugeScreen) {
      return "  w-96";
    } else if (isAboveLargeScreen) {
      return " w-96";
    } else if (isAboveMediumScreen || isAboveSmallScreens) {
      return "w-72";
    } else {
      return " w-72";
    }
  }

  return (
    <nav id="navDiv">
      <div className={`${flexBetween}  top-0 z-30 w-full `}>
        <div className={`${flexBetween} ${getHeight()} w-full`}>
          <div
            className={`${flexBetween} w-full px-0  ${
              isAboveSmallScreens ? ` ${getSpacing()} ` : ""
            }`}
          >
            <div className={`${flexBetween} w-full `}>
              <div
                className={`${flexBetween} ${getBannerDimens()}  ${
                  isAboveSmallScreens ? " " : " ml-[calc(20px)]"
                }`}
              >
                <img alt="logo" src={LogoHorizontal} />
              </div>
            </div>
            {isAboveSmallScreens && (
              <div className={`${flexBetween} `}>
                <div className={`${flexBetween} gap-6 ${getTextDimen()}`}>
                  <LinkItem
                    page="HOME"
                    selectedPage={selectedPage}
                    setSelectedPage={setSelectedPage}
                  />
                  <LinkItem
                    page="SERVICES"
                    selectedPage={selectedPage}
                    setSelectedPage={setSelectedPage}
                  />
                  <LinkItem
                    page="PROJECTS"
                    selectedPage={selectedPage}
                    setSelectedPage={setSelectedPage}
                  />
            
                  <LinkItem
                    page="ABOUT"
                    selectedPage={selectedPage}
                    setSelectedPage={setSelectedPage}
                  />
                  <LinkItem
                    page="CONTACT"
                    selectedPage={selectedPage}
                    setSelectedPage={setSelectedPage}
                  />
                </div>
              </div>
            )}
          </div>
          <HamburgerMenu
            isMenuToggled={isMenuToggled}
            setIsMenuToggled={setIsMenuToggled}
            style={{ zIndex: 80 }}
          />
        </div>
        <MenuPage
          isMenuToggled={isMenuToggled}
          selectedPage={selectedPage}
          setIsMenuToggled={setIsMenuToggled}
          setSelectedPage={setSelectedPage}
        />
      </div>
    </nav>
  );
};

export default Navbar;
