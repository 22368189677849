import AnchorLink from "react-anchor-link-smooth-scroll";
import BannerImage from "../../../../assets/banner.png";
import MouseParticles from "../../../../components/MouseParticles";
import { useEffect, useRef, useState } from "react";
import useMediaQuery from "../../../../hooks/useMediaQuery";
import { Screens } from "../../../../shared/types";
import { useTranslation } from "react-i18next";

type Props = {
  buttonHover: boolean;
  setButtonHover: (value: boolean) => void;
  selectedDiv: String;
  scrollToAbout: () => void;
};

const Home = ({
  buttonHover,
  setButtonHover,
  selectedDiv,
  scrollToAbout,
}: Props) => {
  const isAboveHugeScreen = useMediaQuery(Screens.Huge);
  const isAboveLargeScreen = useMediaQuery(Screens.Large);
  const isAboveMediumScreen = useMediaQuery(Screens.Medium);
  const isAboveSmallScreen = useMediaQuery(Screens.Small);
  const isTablet = useMediaQuery(Screens.TabletSize);

  function getTitleDimens(): String {
    if (isAboveHugeScreen) {
      return "text-9xl";
    } else if (isAboveLargeScreen) {
      return "text-9xl";
    } else if (isAboveMediumScreen) {
      return "text-7xl";
    } else if (isAboveSmallScreen) {
      return "text-4xl";
    } else {
      return "text-4xl text-center";
    }
  }

  function getSubtitleDimens(): String {
    if (isAboveHugeScreen) {
      return "text-7xl";
    } else if (isAboveLargeScreen) {
      return "text-7xl";
    } else if (isAboveMediumScreen) {
      return "text-4xl";
    } else if (isAboveSmallScreen) {
      return "text-xl";
    } else {
      return "text-xl text-center";
    }
  }

  function getButtonTextDimens(): String {
    if (isAboveHugeScreen) {
      return "text-4xl";
    } else if (isAboveLargeScreen) {
      return "text-4xl";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "text-xl";
    } else {
      return "text-base";
    }
  }

  function getButtonTextDimensHover(): String {
    if (isAboveHugeScreen) {
      return "text-3xl";
    } else if (isAboveLargeScreen) {
      return "text-3xl";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "text-lg";
    } else {
      return "text-lg";
    }
  }

  function getPageDimens(): String {
    if (isAboveHugeScreen) {
      return "h-[calc(100vh-180px)]]";
    } else if (isAboveLargeScreen) {
      return " h-[calc(100vh-180px)]";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return " h-[calc(100vh-120px)]";
    } else {
      return "h-[calc(100vh-120px)]]";
    }
  }

  const {t, i18n} = useTranslation();

  return (
    <div
      className={`relative  w-full snap-start bg-cover bg-center ${
        isAboveSmallScreen
          ? `${getPageDimens()}`
          : ` ${
              isTablet ? "h-screen" : "h-[calc(100vh-120px)]"
            }  relative w-full snap-start overflow-hidden bg-cover bg-center bg-no-repeat  `
      }`}
      style={{
        backgroundImage: `url(${BannerImage})`,
        backgroundPosition: isAboveSmallScreen ? "" : "left",
      }}
    >
      {selectedDiv === "home" && <MouseParticles />}
      <div
        className={`absolute bottom-0 right-0 top-0 flex flex-col  justify-center gap-10 ${
          isAboveSmallScreen
            ? "left-[calc(120px)]  items-start"
            : "left-0 items-center"
        }`}
      >
        <div className={`${getTitleDimens()} text-white`}>EveryBotics srl</div>
        <div className={`${getSubtitleDimens()} text-white`}>
          <p> {t('tailoredHome')} </p>
          <p>  {t('theFutureHome')} </p>
        </div>
        <div
          className={`bg-peach  ${
            buttonHover
              ? ` p-1 text-center ${getButtonTextDimensHover()}`
              : `p-1 text-center ${getButtonTextDimens()}`
          } transition-width duration-300 ease-in-out`}
          onMouseEnter={() => setButtonHover(true)}
          onMouseLeave={() => setButtonHover(false)}
        >
          <button
            className="w-full px-10 py-1 font-semibold "
            onClick={scrollToAbout}
          >
            {t('explore')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Home;
