import { SelectedService } from "../../shared/types";
import ITServicesContent from "./ITServicesContent";
import AIContent from "./aiContent";
import CodingContent from "./codingContent";
import RoboticsContent from "./roboticsContent";
import WebSitesContent from "./webSitesContent";
import CyberContent from "./cybersecurityContent";

type Props = {
  selectedService: SelectedService;
};

const ServiceContent = ({ selectedService }: Props) => {
  function switchContent(): JSX.Element {
    switch (selectedService) {
      case SelectedService.Coding:
        return <CodingContent />;
      case SelectedService.AI:
        return <AIContent />;
      case SelectedService.Robotics:
        return <RoboticsContent />
      case SelectedService.WebSites:
        return <WebSitesContent />
      case SelectedService.Cybersecurity:
          return <CyberContent />
      case SelectedService.Services:
        return <ITServicesContent />
    }
    return <div></div>;
  }

  return switchContent();
};

export default ServiceContent;
