import { useCallback, useRef } from "react";
import FirstPage from "../../../components/firstPage";
import ContentPage from "../../../components/page";
import ScrollToTopButton from "../../../components/ScrollToTopButton";
import Pic from "../../../assets/everybot.png";
import FirstPageMobile from "../../../components/firstPageMobile";
import ContentPageMobile from "../../../components/pageMobile";
import { useTranslation } from "react-i18next";
import VideoPlayer from "../../../components/VideoPlayer";
import VideoPlayerMobile from "../../../components/VideoPlayerMobile";

type Props = {
  selectedDiv: String;
  setSelectedDiv: (value: String) => void;
  isMenuToggled: boolean;
  setIsMenuToggled: (value: boolean) => void;
};

const AboutMobile = ({
  selectedDiv,
  setSelectedDiv,
  isMenuToggled,
  setIsMenuToggled,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  const scrollToRef = () => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const { t, i18n } = useTranslation();

  return (
    <main
      id="aboutPage"
      style={{ padding: 0, margin: 0, boxSizing: "border-box" }}
      className="no-scrollbar flex-1     overflow-y-auto overflow-x-hidden"
    >
      <div className="h-full w-full snap-start">
        <VideoPlayerMobile />
      </div>

    </main>
  );
};

export default AboutMobile;
