import { useState, useEffect } from "react";
import Items from "./items";
import { SelectedService } from "../../shared/types";

type Props = {
  selectedService: SelectedService;
  setSelectedService: (value: SelectedService) => void;
};

const ServicesMenu = ({selectedService, setSelectedService}: Props) => {
 
  return (
    <div className="relative  mr-[calc(60px)] my-[calc(60px)] flex w-1/2 flex-col items-start justify-end">
      <Items
        service="CODING"
        selectedService={selectedService}
        setSelectedService={setSelectedService}
      />
      <Items
        service="A.I."
        selectedService={selectedService}
        setSelectedService={setSelectedService}
      />
      <Items
        service="ROBOTICS"
        selectedService={selectedService}
        setSelectedService={setSelectedService}
      />
      <Items
        service="WEBSITES"
        selectedService={selectedService}
        setSelectedService={setSelectedService}
      />
      <Items
        service="CYBERSECURITY"
        selectedService={selectedService}
        setSelectedService={setSelectedService}
      />
      <Items
        service="SERVICES"
        selectedService={selectedService}
        setSelectedService={setSelectedService}
      />
    </div>
  );
};

export default ServicesMenu;
