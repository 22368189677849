import React from "react";

interface GameBoardProps {
  children?: React.ReactNode;
}

const GameBoard: React.FC<GameBoardProps> = (props) => {
  return <div className="relative  w-full h-full m-auto bg-blue-black">{props.children}</div>;
};

export default GameBoard;