// ServiceSection.tsx
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useMediaQuery from "../../../hooks/useMediaQuery";
import { Screens, SelectedPage } from "../../../shared/types";
import { NavLink } from "react-router-dom";

type Props = {
  title: string;
  description: string;
  imageUrl: string;
  setSelectedPage(value: SelectedPage): void;
};

const ServiceSectionMobile = ({
  title,
  description,
  imageUrl,
  setSelectedPage,
}: Props) => {
  const isAboveHugeScreen = useMediaQuery(Screens.Huge);
  const isAboveLargeScreen = useMediaQuery(Screens.Large);
  const isAboveMediumScreen = useMediaQuery(Screens.Medium);
  const isAboveSmallScreen = useMediaQuery(Screens.Small);
  const [buttonHover, setButtonHover] = useState<boolean>(false);
  const { t, i18n } = useTranslation();

  function getButtonTextDimens(): String {
    if (isAboveHugeScreen) {
      return "text-3xl";
    } else if (isAboveLargeScreen) {
      return "text-3xl";
    } else if (isAboveMediumScreen) {
      return "text-xl";
    } else if (isAboveSmallScreen) {
      return "text-base";
    } else {
      return "text-base";
    }
  }

  function getPageDimens(): String {
    if (isAboveHugeScreen) {
      return "h-[calc(100vh-180px)]]";
    } else if (isAboveLargeScreen) {
      return " h-[calc(100vh-180px)]";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return " h-[calc(100vh-120px)]";
    } else {
      return "h-[calc(100vh-120px)]]";
    }
  }

  function getTitleDimens(): String {
    if (isAboveHugeScreen) {
      return "text-8xl";
    } else if (isAboveLargeScreen) {
      return "text-8xl";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "text-6xl";
    } else {
      return "text-4xl";
    }
  }

  function getStringDimens(): String {
    if (isAboveHugeScreen) {
      return "text-2xl";
    } else if (isAboveLargeScreen) {
      return "text-2xl";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "text-xl";
    } else {
      return "text-base";
    }
  }


  return (
    <div className={`relative snap-start overflow-hidden bg-black`}>
      <div className={`relative mx-auto max-w-screen-xl ${getPageDimens()}`}>
        <div className="relative flex h-screen items-center justify-center">
          <img
            src={imageUrl}
            alt={title}
            className="absolute inset-0 h-full w-full transform object-cover transition-transform hover:scale-105"
          />{" "}
          <div className="absolute inset-0 bg-black opacity-70"></div>
          <div
            className={`absolute inset-0 z-10 ${
              isAboveMediumScreen
                ? "  items-start justify-end w-2/3 h-2/3 mx-[calc(60px)]" 
                : "mx-[calc(20px)] items-center justify-center mt-[calc(40px)]"
            } flex flex-col   text-white`}
          >
            <h2 className={`mb-4 ${getTitleDimens()} font-bold text-white text-right`}>
              {title}
            </h2>
            <p className={`${isAboveMediumScreen ? "text-justify" : "text-center"}   ${getStringDimens()} text-white`}>{description}</p>

            <div
              className={` ${isAboveMediumScreen ? "w-1/3" : ""}  ${
                buttonHover ? "text-cobalt" : "text-white"
              }  mt-[calc(20px)] border-2 border-white ${
                buttonHover
                  ? `bg-white p-1 text-center ${getButtonTextDimens()}`
                  : `p-1 text-center ${getButtonTextDimens()}`
              } transition-colors duration-300 ease-in-out`}
              onMouseEnter={() => setButtonHover(true)}
              onMouseLeave={() => setButtonHover(false)}
            >
              {" "}
              <NavLink
                to={`/contact`}
                onClick={() => {
                  setSelectedPage(SelectedPage.Contact);
                }}
              >
                <button className="w-full px-10 py-1 font-semibold ">
                  {t("contactUs")}
                </button>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceSectionMobile;
