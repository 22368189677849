import { useState } from "react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/solid";
import Banner from "../../assets/banner.png";
import LogoHorizontal from "../../assets/logo_h.png";
import Link from "./Link";
import { Screens, SelectedPage } from "../../shared/types";
import useMediaQuery from "../../hooks/useMediaQuery";
import HamburgerMenu from "../../components/HamburgerMenu";
import MenuPage from "../menu";
import Items from "./items";

type Props = {
  selectedPage: SelectedPage;
  setSelectedPage: (value: SelectedPage) => void;
  overBar: boolean;
  setOverBar: (vale: boolean) => void;
};

const Sidebar = ({
  selectedPage,
  setSelectedPage,
  overBar,
  setOverBar,
}: Props) => {
  const isAboveHugeScreen = useMediaQuery(Screens.Huge);
  const isAboveLargeScreen = useMediaQuery(Screens.Large);
  const isAboveMediumScreen = useMediaQuery(Screens.Medium);
  const isAboveSmallScreens = useMediaQuery(Screens.Small);

  function getWidth(): String {
    if (isAboveHugeScreen) {
      return " w-whiteBorderXL";
    } else if (isAboveLargeScreen) {
      return " w-whiteBorderL";
    } else if (isAboveMediumScreen || isAboveSmallScreens) {
      return "w-whiteBorderM";
    } else {
      return " w-whiteBorderS";
    }
  }

  function getHeight(): String {
    if (isAboveHugeScreen) {
      return " h-[calc(100vh-90px-90px)] text-lg";
    } else if (isAboveLargeScreen) {
      return "h-[calc(100vh-90px-90px)] text-lg";
    } else if (isAboveMediumScreen || isAboveSmallScreens) {
      return "h-[calc(100vh-60px-60px)] text-base";
    } else {
      return "h-[calc(100vh-60px-60px)] text-base";
    }
  }

  return (
    <div className={`relative ${getWidth()} `}>
      <aside
        className={`
    flex  w-full rotate-180 flex-col items-center justify-center ${getHeight()}`}
        style={{ writingMode: "vertical-lr" }}
      >
      </aside>
      <div
        className={` ${
          overBar ? "opacity-100" : "opacity-0"
        }  bg-white transition-opacity duration-500 ease-in-out`}
      >
        <Items selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
      </div>
    </div>
  );
};

export default Sidebar;
