import { NavLink } from "react-router-dom";
import YellowLine from "../../../../assets/yellow_line.png";
import MouseParticles from "../../../../components/MouseParticles";
import ScreenParticles from "../../../../components/ScreenParticles";
import { Screens, SelectedPage } from "../../../../shared/types";
import useMediaQuery from "../../../../hooks/useMediaQuery";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  selectedDiv: String;
  setSelectedPage(value: SelectedPage): void;
};

const AboutMobile = ({ selectedDiv, setSelectedPage }: Props) => {
  const isAboveHugeScreen = useMediaQuery(Screens.Huge);
  const isAboveLargeScreen = useMediaQuery(Screens.Large);
  const isAboveMediumScreen = useMediaQuery(Screens.Medium);
  const isAboveSmallScreen = useMediaQuery(Screens.Small);

  function getTitleDimens(): String {
    if (isAboveHugeScreen) {
      return "text-6xl";
    } else if (isAboveLargeScreen) {
      return "text-6xl";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "text-4xl";
    } else {
      return "text-2xl";
    }
  }

  function getSubtitleDimens(): String {
    if (isAboveHugeScreen) {
      return "text-4xl";
    } else if (isAboveLargeScreen) {
      return "text-4xl";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "text-2xl";
    } else {
      return "text-xl";
    }
  }

  function getStringDimens(): String {
    if (isAboveHugeScreen) {
      return "text-xl";
    } else if (isAboveLargeScreen) {
      return "text-xl";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "text-lg";
    } else {
      return "text-sm";
    }
  }

  function getTextWidth(): String {
    if (isAboveHugeScreen) {
      return "w-3/6";
    } else if (isAboveLargeScreen) {
      return "w-3/6";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "w-4/6";
    } else {
      return "w-4/6";
    }
  }

  function getLineMargin(): String {
    if (isAboveHugeScreen) {
      return "-ml-[calc(100px)]";
    } else if (isAboveLargeScreen) {
      return "-ml-[calc(100px)]";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "-ml-[calc(60px)]";
    } else {
      return "-ml-[calc(40px)]";
    }
  }

  function getPageDimens(): String {
    if (isAboveHugeScreen) {
      return "h-[calc(100vh-180px)]]";
    } else if (isAboveLargeScreen) {
      return " h-[calc(100vh-180px)]";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return " h-[calc(100vh-120px)]";
    } else {
      return "h-[calc(100vh-120px)]]";
    }
  }

  const [buttonHover, setButtonHover] = useState<boolean>(false);

  const isTablet = useMediaQuery(Screens.TabletSize);

  const { t, i18n } = useTranslation();

  return (
    <div
      className={` ${
        isTablet ? "h-screen" : "h-[calc(100vh-120px)]"
      }  relative w-full snap-start overflow-hidden bg-cover bg-center bg-no-repeat`}
      id="about"
    >
      <div className="relative flex h-full w-full flex-col items-center justify-center ">
        <div className=" flex w-full flex-col items-center justify-center gap-6 ">
          <div
            className={`text-center ${getTitleDimens()} font-semibold text-cobalt `}
          >
            {t("aboutUs")}
          </div>
          <div className="">
            <img src={YellowLine} alt="" className="h-1 w-4" />
          </div>
          <div className={`text-center ${getSubtitleDimens()} `}>
            EveryBotics srl
          </div>
          <div
            className={`${getTextWidth()} text-justify ${getStringDimens()}`}
          >
            {t("about1")}
            <span className="font-semibold text-cobalt"> {t("about2")} </span>
            {t("about3")}
            <span className="font-semibold text-cobalt"> {t("about4")} </span>
            {t("about5")}
          </div>
          <NavLink
            to={`/contact`}
            onClick={() => {
              setSelectedPage(SelectedPage.Contact);
            }}
          >
            <div
              className={` ${
                buttonHover ? "text-white" : "text-peach"
              }  border-2 border-peach ${
                buttonHover
                  ? `text-base} bg-peach p-1 text-center`
                  : `text-base} p-1 text-center`
              } transition-colors duration-300 ease-in-out`}
              onMouseEnter={() => setButtonHover(true)}
              onMouseLeave={() => setButtonHover(false)}
            >
              <button className="w-full px-10 py-1 font-semibold ">
              {t("contactUs")}
              </button>
            </div>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default AboutMobile;
