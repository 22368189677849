import { NavLink } from "react-router-dom";
import YellowLine from "../../../../assets/yellow_line.png";
import MouseParticles from "../../../../components/MouseParticles";
import ScreenParticles from "../../../../components/ScreenParticles";
import { Screens, SelectedPage } from "../../../../shared/types";
import useMediaQuery from "../../../../hooks/useMediaQuery";
import { useTranslation } from "react-i18next";
import { useState } from "react";

type Props = {
  selectedDiv: String;
  setSelectedPage(value: SelectedPage): void;
};

const About = ({ selectedDiv, setSelectedPage }: Props) => {
  const isAboveHugeScreen = useMediaQuery(Screens.Huge);
  const isAboveLargeScreen = useMediaQuery(Screens.Large);
  const isAboveMediumScreen = useMediaQuery(Screens.Medium);
  const isAboveSmallScreen = useMediaQuery(Screens.Small);
  const [buttonHover, setButtonHover] = useState<boolean>(false);


  function getTitleDimens(): String {
    if (isAboveHugeScreen) {
      return "text-8xl";
    } else if (isAboveLargeScreen) {
      return "text-8xl";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "text-6xl";
    } else {
      return "text-4xl";
    }
  }

  function getSubtitleDimens(): String {
    if (isAboveHugeScreen) {
      return "text-6xl";
    } else if (isAboveLargeScreen) {
      return "text-6xl";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "text-4xl";
    } else {
      return "text-2xl";
    }
  }

  function getStringDimens(): String {
    if (isAboveHugeScreen) {
      return "text-2xl";
    } else if (isAboveLargeScreen) {
      return "text-2xl";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "text-xl";
    } else {
      return "text-lg";
    }
  }

  function getTextWidth(): String {
    if (isAboveHugeScreen) {
      return "w-3/6";
    } else if (isAboveLargeScreen) {
      return "w-3/6";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "w-3/6";
    } else {
      return "w-3/6";
    }
  }

  function getLineMargin(): String {
    if (isAboveHugeScreen) {
      return "-ml-[calc(100px)]";
    } else if (isAboveLargeScreen) {
      return "-ml-[calc(100px)]";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "-ml-[calc(60px)]";
    } else {
      return "-ml-[calc(40px)]";
    }
  }

  function getPageDimens(): String {
    if (isAboveHugeScreen) {
      return "h-[calc(100vh-180px)]]";
    } else if (isAboveLargeScreen) {
      return " h-[calc(100vh-180px)]";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return " h-[calc(100vh-120px)]";
    } else {
      return "h-[calc(100vh-120px)]]";
    }
  }



  function getButtonTextDimens(): String {
    if (isAboveHugeScreen) {
      return "text-4xl";
    } else if (isAboveLargeScreen) {
      return "text-4xl";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "text-2xl";
    } 
    else {
      return "text-base";
    }
  }

  const { t, i18n } = useTranslation();

  return (
    <div
      className={`relative ${getPageDimens()} w-full snap-start bg-cover bg-center`}
      id="about"
    >
      {(selectedDiv === "about" || selectedDiv === "aboutPage") && (
        <ScreenParticles />
      )}
      <div
        className="absolute bottom-0 left-0 right-0
       top-0 flex w-full flex-col items-center justify-center"
      >
        <div className="flex w-full items-center justify-center">
          <div
            className={`flex  flex-col justify-center gap-10 ${getTextWidth()}`}
          >
            <div
              className={`text-left ${getTitleDimens()} font-semibold text-cobalt ${getTextWidth()}`}
            >
              {t("aboutUs")}
            </div>
            <div className={`${getLineMargin()} }`}>
              <img src={YellowLine} alt="" className="-my-4 h-3 w-1/6" />
            </div>
            <div className={`text-left ${getSubtitleDimens()}  -mt-4`}>
              EveryBotics srl
            </div>
            <div className={` text-justify ${getStringDimens()}`}>
              {t("about1")}
              <span className="font-semibold text-cobalt">
                {" "}
                {t("about2")}
              </span>{" "}
              {t("about3")}
              <span className="font-semibold text-cobalt">
                {" "}
                {t("about4")}
              </span>{" "}
              {t("about5")}
              <NavLink
                to={`/about`}
                onClick={() => {
                  setSelectedPage(SelectedPage.About);
                }}
              >
                <span className="font-semibold text-cobalt">
                  {" "}
                  {t("readMore")}{" "}
                </span>
              </NavLink>
            </div>
            <div
              className={` w-1/3 ${
                buttonHover ? "text-white" : "text-peach"
              } border-2 border-peach ${
                buttonHover
                  ? `bg-peach p-1 text-center ${getButtonTextDimens()} `
                  : `p-1 text-center ${getButtonTextDimens()} `
              } transition-colors duration-300 ease-in-out`}
              onMouseEnter={() => setButtonHover(true)}
              onMouseLeave={() => setButtonHover(false)}
            >
              <NavLink
                to={`/contact`}
                onClick={() => {
                  setSelectedPage(SelectedPage.Contact);
                }}
              >
                <button className="w-full px-10 py-1 font-semibold ">
                  {t("contactUs")}
                </button>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
