import { useState, useEffect } from "react";
import YellowLine from "../../../../assets/vertical_line_yellow.png";
import BannerLine from "../../../../assets/banner_line.png";
import ServicesMenu from "../../../servicesMenu";
import {
  Screens,
  SelectedPage,
  SelectedService,
  services,
} from "../../../../shared/types";
import { NavLink } from "react-router-dom";
import useMediaQuery from "../../../../hooks/useMediaQuery";
import { useDebounce } from "use-debounce";
import { useTranslation } from "react-i18next";

type Props = {
  selectedDiv: String;
  selectedService: SelectedService;
  setSelectedService: (value: SelectedService) => void;
  setSelectedPage(value: SelectedPage): void;
};

const OurServices = ({
  selectedDiv,
  selectedService,
  setSelectedService,
  setSelectedPage,
}: Props) => {
  const [buttonHover, setButtonHover] = useState<boolean>(false);

  const isAboveHugeScreen = useMediaQuery(Screens.Huge);
  const isAboveLargeScreen = useMediaQuery(Screens.Large);
  const isAboveMediumScreen = useMediaQuery(Screens.Medium);
  const isAboveSmallScreen = useMediaQuery(Screens.Small);

  function getTitleDimens(): String {
    if (isAboveHugeScreen) {
      return "text-8xl";
    } else if (isAboveLargeScreen) {
      return "text-8xl";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "text-6xl";
    } else {
      return "text-4xl";
    }
  }

  function getButtonTextDimens(): String {
    if (isAboveHugeScreen) {
      return "text-4xl";
    } else if (isAboveLargeScreen) {
      return "text-4xl";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "text-2xl";
    } 
    else {
      return "text-base";
    }
  }

  /*const [serviceSelectedIndex, setServiceSelectedIndex] = useState<number>(0);
  const [servicePreviousIndex, setServicePreviousIndex] = useState<number>(-1);
  const [debouncedHandleScroll] = useDebounce((event: WheelEvent) => {
    const initialIndex = serviceSelectedIndex;

    let i = initialIndex;
    const ourServicesDiv = document.getElementById("ourServices");

    if (event instanceof WheelEvent) {
      if (ourServicesDiv && selectedDiv === "ourServices") {
        if (event.deltaY < 0 && i > 0) {
          i--;
        } else if (event.deltaY > 0 && i < services.length - 1) {
          i++;
        }

        setServicePreviousIndex(serviceSelectedIndex);
        setServiceSelectedIndex(i);
        setSelectedService(services[i] as SelectedService);
      }
    }
  }, 600);

  useEffect(() => {
    const ourServicesDiv = document.getElementById("ourServices");

    const preventDefaultScroll = (event: WheelEvent) => {
      if (ourServicesDiv && selectedDiv === "ourServices") {
        if (serviceSelectedIndex === 0) {
          if (event.deltaY > 0) {
            event.preventDefault();
          } else if (event.deltaY < 0 && servicePreviousIndex === 1) {
            event.preventDefault();
          }
        } else if (serviceSelectedIndex === services.length - 1) {
          if (event.deltaY < 0) {
            event.preventDefault();
          } else if (
            event.deltaY > 0 &&
            servicePreviousIndex === services.length - 2
          ) {
            event.preventDefault();
          }
        } else if (
          serviceSelectedIndex !== 0 &&
          serviceSelectedIndex !== services.length - 1
        ) {
          event.preventDefault();
        }
      } else {
        setServicePreviousIndex(-1);
      }
    };

    window.addEventListener("wheel", preventDefaultScroll, {
      passive: false,
    });

    return () => {
      window.removeEventListener("wheel", preventDefaultScroll);
    };
  }, [selectedService, selectedDiv, servicePreviousIndex]);

  useEffect(() => {
    const ourServicesDiv = document.getElementById("ourServices");
    const handleScroll = (event: WheelEvent) => {
      debouncedHandleScroll(event);
    };

    ourServicesDiv?.addEventListener("wheel", handleScroll);

    return () => {
      ourServicesDiv?.removeEventListener("wheel", handleScroll);
    };
  }, [debouncedHandleScroll, servicePreviousIndex, selectedDiv]);
*/
  function getPageDimens(): String {
    if (isAboveHugeScreen) {
      return "h-[calc(100vh-180px)]]";
    } else if (isAboveLargeScreen) {
      return " h-[calc(100vh-180px)]";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return " h-[calc(100vh-120px)]";
    } else {
      return "h-[calc(100vh-120px)]]";
    }
  }

  const {t, i18n} = useTranslation();

  return (
    <div
      className={`relative ${getPageDimens()} w-full snap-start overflow-hidden bg-cover bg-center bg-no-repeat`}
      id="ourServices"
      style={{
        backgroundImage: `url(${BannerLine})`,
      }}
    >
      <div className="flex h-full w-full">
        <div className="relative bottom-0 left-0 ml-[calc(120px)] mt-60 w-10 "></div>

        <div className="relative ml-[calc(60px)] flex h-full w-2/5 flex-col items-start justify-center">
          <div
            className={`text-left ${getTitleDimens()} font-semibold text-cobalt`}
          >
            <p className="mb-6">{t("ourServices")}</p>
            <p>{t("forYou")}</p>
          </div>
          <div
            className={`absolute ${
              buttonHover ? "text-white" : "text-peach"
            } bottom-[calc(60px)] border-2 border-peach ${
              buttonHover
                ? `bg-peach p-1 text-center ${getButtonTextDimens()} `
                : `p-1 text-center ${getButtonTextDimens()} `
            } transition-colors duration-300 ease-in-out`}
            onMouseEnter={() => setButtonHover(true)}
            onMouseLeave={() => setButtonHover(false)}
          >
            <NavLink
              to={`/contact`}
              onClick={() => {
                setSelectedPage(SelectedPage.Contact);
              }}
            >
              <button className="w-full px-10 py-1 font-semibold ">
                {t("contactUs")}
              </button>
            </NavLink>
          </div>
        </div>

        <ServicesMenu
          selectedService={selectedService}
          setSelectedService={setSelectedService}
        />
      </div>
    </div>
  );
};

export default OurServices;
