// src/components/LanguageSwitcher.tsx
import React from "react";
import { useTranslation } from "react-i18next";
import it from "../flags/it.png";
import en from "../flags/en.png";
import useMediaQuery from "../hooks/useMediaQuery";
import { Screens } from "../shared/types";

const languages = [
  { code: "en", label: "English", flag: en },
  { code: "it", label: "Italian", flag: it },
  // Add more languages as needed
];

type Props = {
  menu: boolean;
  bigScreen: boolean;
};

const LanguageSwitcher = ({ menu, bigScreen }: Props) => {
  const { i18n } = useTranslation();

  const isAboveMediumScreens = useMediaQuery(Screens.TabletSize);

  const changeLanguage = (languageCode: string) => {
    i18n.changeLanguage(languageCode);
  };

  return (
    <div
      className={`flex flex-row gap-4 ${
        bigScreen ? "w-1/4" : isAboveMediumScreens ? "w-1/12" : "w-1/6"
      } `}
    >
      {languages.map((lang) => (
        <button
          key={lang.code}
          onClick={() => changeLanguage(lang.code)}
          className="w-full focus:outline-none"
        >
          <img src={lang.flag} alt={lang.label} className="w-full" />
        </button>
      ))}
    </div>
  );
};

export default LanguageSwitcher;
