import React from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";

const VideoPlayer: React.FC = () => {
  const videoUrl = "https://youtu.be/awtz4cRx9NE&rel=0"; // Replace with your YouTube video URL
  const { t, i18n } = useTranslation();

  return (
    <div className="h-full w-full flex justify-center items-center ">
      <div className="video-wrapper h-[calc(90%)] w-[calc(90%)]">
        <ReactPlayer
          url={videoUrl}
          width="100%"
          height="100%"
          playing={true} // Auto-start the video
          controls={false} // Show video controls
          config={{
            youtube: {
              playerVars: {
                modestbranding: 1, // Hide YouTube
                rel: 0, // Disable related videos
              },
            },
          }}
        />
      </div> 
    </div>
  );
};

export default VideoPlayer;
