import { useTranslation } from "react-i18next";
import Cybersecurity from "../../assets/pc_cyber.png";
import useMediaQuery from "../../hooks/useMediaQuery";
import { Screens } from "../../shared/types";

type Props = {};

const CyberContentMobile = ({}: Props) => {
  const isAboveHugeScreen = useMediaQuery(Screens.Huge);
  const isAboveLargeScreen = useMediaQuery(Screens.Large);
  const isAboveMediumScreen = useMediaQuery(Screens.Medium);
  const isAboveSmallScreen = useMediaQuery(Screens.Small);

  function getWidthImageDimensions(): String {
    if (isAboveHugeScreen) {
      return "w-2/4";
    } else if (isAboveLargeScreen) {
      return "w-2/4";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "w-2/5";
    } else {
      return "w-2/4";
    }
  }

  function getTextWidth(): String {
    if (isAboveHugeScreen) {
      return "w-2/4";
    } else if (isAboveLargeScreen) {
      return "w-2/4";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "w-3/4";
    } else {
      return "w-3/4";
    }
  }
  const isTablet = useMediaQuery(Screens.TabletSize);

  const {t, i18n} = useTranslation();

  return (
    <div
      className={`relative  ${
        isTablet ? "h-screen" : "h-[calc(100vh-120px)]"
      } w-full snap-start overflow-hidden bg-cobalt bg-cover bg-center  bg-no-repeat `}
      id="roboticsMobile"
    >
      <div className=" flex h-full flex-col items-center justify-start  gap-[calc(20px)] text-justify text-white">
        <div
          className={`mt-[calc(40px)] flex flex-row  text-2xl font-semibold`}
        >
          {t("ourServices")}
        </div>

        <div className={`flex flex-row text-4xl  font-semibold`}>{t("Cybersecurity")}</div>
        <div className={`${getWidthImageDimensions()}`}>
          <img
            src={Cybersecurity}
            alt=""
            className={`${getWidthImageDimensions()} mx-auto`}
          />
        </div>
        <div className={`${getTextWidth()} text-sm`}>{t("cybersecurityContentSmall")}</div>
      </div>
    </div>
  );
};

export default CyberContentMobile;
