import { useTranslation } from "react-i18next";
import Services from "../../assets/services.png";
import useMediaQuery from "../../hooks/useMediaQuery";
import { Screens } from "../../shared/types";

type Props = {};

const ITServicesContent = ({}: Props) => {
  const isAboveHugeScreen = useMediaQuery(Screens.Huge);
  const isAboveLargeScreen = useMediaQuery(Screens.Large);
  const isAboveMediumScreen = useMediaQuery(Screens.Medium);
  const isAboveSmallScreen = useMediaQuery(Screens.Small);

  function getWidthImageDimensions(): String {
    if (isAboveHugeScreen) {
      return "w-2/4";
    } else if (isAboveLargeScreen) {
      return "w-2/4";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "w-2/5";
    } else {
      return "w-1/4";
    }
  }

  function getTextWidth(): String {
    if (isAboveHugeScreen) {
      return "w-2/4";
    } else if (isAboveLargeScreen) {
      return "w-2/4";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "w-3/4";
    } else {
      return "w-3/4";
    }
  }

  const {t, i18n} = useTranslation();

  return (
    <div className=" flex flex-col items-center justify-center gap-[calc(60px)] text-justify text-white">
   
      <div className={`${getTextWidth()}`}>
        {t("itServicesContentSmall")}
      </div>
    </div>
  );
};

export default ITServicesContent;
