import React from "react";

interface BrickProps {
  svgDataUrl: string;
  width: number;
  height: number;
}

const Brick: React.FC<BrickProps> = ({ svgDataUrl, width, height }) => {
  return (
    <div
      className="brick bg-no-repeat  "
      style={{
        backgroundImage: `url(${svgDataUrl})`,
        backgroundSize: "contain",
        width: `${width}px`,
        height: `${height}px`,
      }}
    />
  );
};

export default Brick;
