import React, { useState } from "react";
import { Transition } from "@headlessui/react";

type Props = {
  children: React.ReactNode;
  clicked: boolean;
};

const AnimatedWordBar = ({ children, clicked }: Props) => {
  const [show, setShow] = useState(false);

  const shouldAnimate = !clicked && show;

  return (
    <span
      className="relative inline-block"
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      <div
        className={`${
          clicked ? "strikethrough-diagonal text-white" : ""
        } hover:text-white`}
      >
        {children}
      </div>
    </span>
  );
};

export default AnimatedWordBar;
