import React from "react";
import ReactPlayer from "react-player";

const VideoPlayerMobile: React.FC = () => {
  const videoUrl = "https://youtube.com/shorts/d2ooENPnEmA";

  return (
    <div className="h-full w-full">
      <div
        className="video-wrapper h-full w-full"
        style={{ position: "relative", paddingTop: "177.77%" }}
      >
        {/* 9:16 aspect ratio */}
        <ReactPlayer
          url={videoUrl}
          style={{ position: "absolute", top: 0, left: 0 }}
          width="100%"
          height="100%"
          playing={true} // Auto-start the video
          controls={false} // Show video controls
          config={{
            youtube: {
              playerVars: {
                modestbranding: 1, // Hide YouTube
                rel: 0, // Disable related videos
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default VideoPlayerMobile;
