import { useState } from "react";
import useMediaQuery from "../hooks/useMediaQuery";
import { Screens } from "../shared/types";

interface TextFieldProps {
  label: string;
  value: string;
  onChange: (newValue: string) => void;
  rows?: number;
  id: string;
  page: boolean;
}

const TextArea: React.FC<TextFieldProps> = ({
  label,
  value,
  onChange,
  rows,
  id,
  page
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(event.target.value);
  };

  const isAboveHugeScreen = useMediaQuery(Screens.Huge);
  const isAboveLargeScreen = useMediaQuery(Screens.Large);
  const isAboveMediumScreen = useMediaQuery(Screens.Medium);
  const isAboveSmallScreen = useMediaQuery(Screens.Small);

  function getTextDimens(): String {
    if (isAboveHugeScreen) {
      return "text-lg";
    } else if (isAboveLargeScreen) {
      return "text-lg";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "text-sm";
    } else {
      return "text-sm";
    }
  }

  return (
    <div className="w-full">
      <textarea
        id={id}
        name={id}
        className={`mt-1 w-full   px-3 py-2    focus:outline-none focus:ring-2 ${
          page
            ? "focus-ring-midnight border-2 border-midnight bg-white text-midnight"
            : "bg-midnight text-white focus:ring-white "
        }${getTextDimens()}`}
        value={value}
        onChange={handleInputChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        placeholder={label}
        rows={rows} // Use the rows prop to set the height of the textarea
      />
    </div>
  );
};

export default TextArea;
