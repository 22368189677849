import BannerImage from "../../../../assets/banner.png";
import MouseParticles from "../../../../components/MouseParticles";
import YellowArrow from "../../../../assets/vertical_line_arrow.png";
import BannerTeam from "../../../../assets/banner_team.png";
import { useEffect, useRef, useState } from "react";
import TeamImage from "../../../../components/teamImage";
import DiVito from "../../../../assets/divitoBlack.png";
import Gillini from "../../../../assets/gilliniBlack.png";
import DiLillo from "../../../../assets/dililloBlack.png";
import DElia from "../../../../assets/deliaBlack.png";
import Marino from "../../../../assets/marinoBlack.png";
import Arrichiello from "../../../../assets/arrichielloBlack.png";
import Antonelli from "../../../../assets/antonelliBlack.png";
import { Screens, SelectedPage } from "../../../../shared/types";
import TeamImageMobile from "../../../../components/teamImageMobile";
import useMediaQuery from "../../../../hooks/useMediaQuery";
import { useTranslation } from "react-i18next";

type Props = { setSelectedPage: (value: SelectedPage) => void };

const TeamMobile = ({ setSelectedPage }: Props) => {
  const isTablet = useMediaQuery(Screens.TabletSize);

  const { t, i18n } = useTranslation();

  return (
    <div
      className={`relative ${
        isTablet ? "h-screen" : "h-[calc(100vh-120px)]"
      }  w-full snap-start overflow-hidden bg-blue-black bg-cover bg-center bg-no-repeat px-[calc(60px)] pt-[calc(20px)]`}
      id="team"
    >
      <div
        className={`flex ${
          isTablet ? "h-screen" : "h-[calc(100vh-120px)]"
        } w-full flex-col  items-center justify-start `}
      >
        <div className="pb-[calc(40px)] text-2xl text-white"></div>
        {isTablet ? (
          <div
            className={`flex h-[calc(100vh-120px-160px)] w-3/4 flex-row   items-center justify-center   gap-[calc(60px)]`}
          >
            <TeamImageMobile
              image={Marino}
              name="Alessandro Marino"
              job={t("adCom")}
              index={5}
              website="http://webuser3.unicas.it/alessandro-marino/index.php"
              setSelectedPage={setSelectedPage}
            />

            <TeamImageMobile
              image={Arrichiello}
              name="Filippo Arrichiello"
              job={t("viceChairman")}
              index={6}
              website="http://webuser.unicas.it/arrichiello/"
              setSelectedPage={setSelectedPage}
            />
          </div>
        ) : (
          <div
            className={`flex h-[calc(100vh-120px-120px)] w-3/4  flex-col   items-center justify-center   gap-[calc(60px)]`}
          >
            <TeamImageMobile
              image={Marino}
              name="Alessandro Marino"
              job={t("adCom")}
              index={5}
              website="http://webuser3.unicas.it/alessandro-marino/index.php"
              setSelectedPage={setSelectedPage}
            />

            <TeamImageMobile
              image={Arrichiello}
              name="Filippo Arrichiello"
              job={t("viceChairman")}
              index={6}
              website="http://webuser.unicas.it/arrichiello/"
              setSelectedPage={setSelectedPage}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TeamMobile;
