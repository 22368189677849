import { Particles } from "react-tsparticles";
import { useCallback } from "react";
import { loadFull } from "tsparticles";
import { Container, Engine } from "tsparticles-engine";
import LogoTransp from "../assets/banner_logo.png";

type Props = {};

const ScreenParticles = ({}: Props) => {
  const particlesLoaded = useCallback(
    async (container: Container | undefined) => {
    },
    []
  );

  const particlesInit = useCallback(async (engine: Engine) => {
    await loadFull(engine);
  }, []);

  return (
    <Particles
      className="h-[calc(100vh-60px-56px)] w-full "
      id="screenParticles"
      init={particlesInit}
      loaded={particlesLoaded}
      options={{
        background: {
          color: {
            value: "#FFFFFF",
          },
        },
        fpsLimit: 60,

        fullScreen: {
          enable: false,
          zIndex: -1,
        },
        interactivity: {
          events: {
            onClick: {
              enable: true,
              mode: "push",
            },
            onHover: {
              enable: true,
              mode: "repulse",
            },
            resize: true,
          },
          modes: {
            grab: {
              distance: 400,
              line_linked: {
                opacity: 1,
              },
            },
            bubble: {
              distance: 400,
              size: 40,
              duration: 2,
              opacity: 0.8,
              speed: 3,
            },
            push: {
              particles_nb: 4,
            },
            remove: {
              particles_nb: 2,
            },
       
          },
        },
        particles: {
          color: {
            value: ["#004aad", "#f8b45b"],
          },

          links: {
            color: "#f8b45b",
            distance: 150,
            enable: true,
            opacity: 0.5,
            width: 1,
          },
          collisions: {
            enable: true,
          },
          move: {
            enable: true,
            speed: 6,
            direction: "none",
            random: false,
            straight: false,
            outMode: "destroy",
            attract: {
              enable: false,
              rotateX: 600,
              rotateY: 1200,
            },
          },
          number: {
            density: {
              enable: true,
              area: 800,
            },
            value: 80,
          },
          opacity: {
            value: 0.5,
          },
          shape: {
            type: "image",
            image: {
              src: LogoTransp,
              width: 200,
              height: 118,
            },
            stroke: {
              width: 1,
              color: "#f8b45b",
            },
          },

          size: {
            value: { min: 5, max: 40 },
          },

        },
        detectRetina: true,
      }}
      style={{ position: "absolute", top: 0, right: 0, bottom: 0, left: 0 }}
    />
  );
};

export default ScreenParticles;
