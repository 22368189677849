import AnchorLink from "react-anchor-link-smooth-scroll";
import { SelectedPage } from "../../shared/types";
import AnimatedOverline from "../../components/AnimatedOverline";
import { useState } from "react";
import {  NavLink } from "react-router-dom";

type Props = {
  page: string;
  selectedPage: SelectedPage;
  setSelectedPage: (value: SelectedPage) => void;
  itemNumber: number;
};

const Link = ({ page, selectedPage, setSelectedPage, itemNumber }: Props) => {
  const lowerCasePage = page
    .toLocaleLowerCase()
    .replace(/ /g, "") as SelectedPage;

  const [isHover, setIsHover] = useState<boolean>(false);

  function getItemWidth(itemNumber: number): String {
    switch (itemNumber) {
      case 0:
        return "w-[calc(280px)]";
      case 1:
        return "w-[calc(260px)]";
      case 2:
        return "w-[calc(240px)]";
      case 3:
        return "w-[calc(220px)]";
      case 4:
        return "w-[calc(200px)]";
      case 5:
        return "w-[calc(180px)]";
    }

    return "";
  }

  return (
    <NavLink
      to={`/${lowerCasePage}`}
      className={`flex h-[calc(60px)] items-center justify-start bg-white pl-4 ${
        isHover ? " w-[calc(300px)]" : getItemWidth(itemNumber)
      } transition-width duration-500 ease-in-out`}
      onClick={() => setSelectedPage(lowerCasePage)}
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      style={{
        boxShadow: "0px 0px 20px 10px rgba(255, 255, 255, 0.1)",
      }}
    >
      {page}
    </NavLink>
  );
};

export default Link;
