import { useRef, useState } from "react";
import YellowLine from "../../../../assets/yellow_line.png";
import TextField from "../../../../components/TextField";
import TextArea from "../../../../components/TextArea";
import emailjs from "@emailjs/browser";
import useMediaQuery from "../../../../hooks/useMediaQuery";
import { Screens } from "../../../../shared/types";
import { useTranslation } from "react-i18next";

type Props = {
  page: boolean;
};

const ContactUs = ({ page }: Props) => {
  const [nameValue, setNameValue] = useState<string>("");
  const [surnameValue, setSurnameValue] = useState<string>("");
  const [emailValue, setEmailValue] = useState<string>("");
  const [companyValue, setCompanyValue] = useState<string>("");
  const [phoneValue, setPhoneValue] = useState<string>("");
  const [messageValue, setMessageValue] = useState<string>("");
  const [acceptPrivacy, setAcceptPrivacy] = useState<boolean>(false);

  const handleNameChanged = (newValue: string) => {
    setNameValue(newValue);
  };
  const handleSurnameChanged = (newValue: string) => {
    setSurnameValue(newValue);
  };
  const handleEmailChanged = (newValue: string) => {
    setEmailValue(newValue);
  };
  const handleCompanyChanged = (newValue: string) => {
    setCompanyValue(newValue);
  };
  const handlePhoneChanged = (newValue: string) => {
    setPhoneValue(newValue);
  };
  const handleMessageChanged = (newValue: string) => {
    setMessageValue(newValue);
  };

  const [buttonHover, setButtonHover] = useState<boolean>(false);

  function reset() {
    setNameValue("");
    setSurnameValue("");
    setEmailValue("");
    setCompanyValue("");
    setPhoneValue("");
    setMessageValue("");
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    /*.sendForm(
        "YOUR_SERVICE_ID",
        "YOUR_TEMPLATE_ID",text-base
        e.target,
        "YOUR_PUBLIC_KEY"
      ) */
    if (
      nameValue == "" ||
      surnameValue == "" ||
      emailValue == "" ||
      messageValue == "" ||
      !acceptPrivacy
    ) {
      alert(t("fillAllFieldsAndAcceptPrivacy"));
    } else {
      emailjs
        .sendForm(
          "service_vgs78wl",
          "template_c9qv4jv",
          event.currentTarget,
          "w4qwgPeyrU-G48TOd"
        )
        .then(
          (result) => {
            console.log(result.text);
            alert(t("messageSent"));
            reset();
          },
          (error) => {
            console.log(error.text);
            alert(error.text);

            reset();
          }
        );
    }
  }

  const isAboveHugeScreen = useMediaQuery(Screens.Huge);
  const isAboveLargeScreen = useMediaQuery(Screens.Large);
  const isAboveMediumScreen = useMediaQuery(Screens.Medium);
  const isAboveSmallScreen = useMediaQuery(Screens.Small);

  function getContainerWidth(): String {
    if (isAboveHugeScreen) {
      return "w-1/2 gap-6";
    } else if (isAboveLargeScreen) {
      return "w-1/2 gap-6";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "w-3/4 gap-6";
    } else {
      return "w-3/4 gap-4";
    }
  }

  function getTitleDimens(): String {
    if (isAboveHugeScreen) {
      return "text-8xl";
    } else if (isAboveLargeScreen) {
      return "text-8xl";
    } else if (isAboveMediumScreen) {
      return "text-6xl";
    } else if (isAboveSmallScreen) {
      return "text-4xl";
    } else {
      return "text-2xl";
    }
  }

  function getSubTitleDimens(): String {
    if (isAboveHugeScreen) {
      return "text-6xl";
    } else if (isAboveLargeScreen) {
      return "text-6xl";
    } else if (isAboveMediumScreen) {
      return "text-4xl";
    } else if (isAboveSmallScreen) {
      return "text-2xl";
    } else {
      return "text-lg";
    }
  }

  function getButtonTextDimens(): String {
    if (isAboveHugeScreen) {
      return "text-3xl";
    } else if (isAboveLargeScreen) {
      return "text-3xl";
    } else if (isAboveMediumScreen) {
      return "text-xl";
    } else if (isAboveSmallScreen) {
      return "text-base";
    } else {
      return "text-base";
    }
  }

  function getGap(): String {
    if (isAboveHugeScreen) {
      return "gap-4 mt-10";
    } else if (isAboveLargeScreen) {
      return "gap-4 mt-10";
    } else if (isAboveMediumScreen) {
      return "gap-4 mt-8";
    } else if (isAboveSmallScreen) {
      return "gap-2 mt-6";
    } else {
      return "gap-2 mt-6";
    }
  }
  function getLineMargin(): String {
    if (isAboveHugeScreen) {
      return "-ml-[calc(120px)]";
    } else if (isAboveLargeScreen) {
      return "-ml-[calc(120px)]";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "-ml-[calc(100px)]";
    } else {
      return "-ml-[calc(80px)]";
    }
  }
  function getLineWidth(): String {
    if (isAboveHugeScreen) {
      return "w-2/6";
    } else if (isAboveLargeScreen) {
      return "w-2/6";
    } else if (isAboveMediumScreen) {
      return "w-2/6";
    } else if (isAboveSmallScreen) {
      return "w-1/6";
    } else {
      return "w-1/6";
    }
  }

  function getPageDimens(): String {
    if (isAboveHugeScreen) {
      return "h-[calc(100vh-180px)]]";
    } else if (isAboveLargeScreen) {
      return " h-[calc(100vh-180px)]";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return " h-[calc(100vh-120px)]";
    } else {
      return "h-[calc(100vh-120px)]]";
    }
  }

  function getTextWidth(): String {
    if (isAboveHugeScreen) {
      return "w-3/4";
    } else if (isAboveLargeScreen) {
      return "w-3/4";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "w-3/4";
    } else {
      return "w-3/4";
    }
  }

  const { t, i18n } = useTranslation();

  return (
    <div
      className={`relative  ${getPageDimens()} w-full snap-start bg-cover bg-center`}
      id="contactus"
    >
      <div
        className="absolute bottom-0 left-0 right-0
       top-0 flex flex-col items-center justify-center "
      >
        <form
          onSubmit={handleSubmit}
          className="absolute bottom-0 left-0 right-0
       top-0 flex w-full flex-col items-center justify-center "
        >
          <div className="flex w-full items-center justify-center">
            <div
              className={`my-[calc(56px)] flex ${getContainerWidth()} flex-col justify-center `}
            >
              <div
                className={`text-left  font-semibold text-cobalt ${getTitleDimens()} `}
              >
                {t("contactUsBig")}
              </div>
              <div className={`${getLineMargin()}`}>
                <img
                  src={YellowLine}
                  alt=""
                  className={`h-3 ${getLineWidth()}`}
                />
              </div>
              <div
                className={`text-left  font-semibold ${getSubTitleDimens()}`}
              >
                {t("sendMail")}
              </div>
              <div
                className={`items-left flex flex-col justify-center ${getGap()}`}
              >
                <div className="flex  w-full flex-row gap-8">
                  <TextField
                    label={t("contactName")}
                    onChange={handleNameChanged}
                    value={nameValue}
                    type="text"
                    id="name"
                    page={page}
                  />
                  <TextField
                    label={t("contactSurname")}
                    onChange={handleSurnameChanged}
                    value={surnameValue}
                    type="text"
                    id="surname"
                    page={page}
                  />
                </div>
                <div className="flex w-full flex-row gap-8">
                  <TextField
                    label={t("contactEmail")}
                    onChange={handleEmailChanged}
                    value={emailValue}
                    type="email"
                    id="email"
                    page={page}
                  />
                </div>
                <div className="flex  w-full flex-row gap-8">
                  <TextField
                    label={t("contactCompany")}
                    onChange={handleCompanyChanged}
                    value={companyValue}
                    type="text"
                    id="company"
                    page={page}
                  />
                  <TextField
                    label={t("contactPhone")}
                    onChange={handlePhoneChanged}
                    value={phoneValue}
                    type="phone"
                    id="phone"
                    page={page}
                  />
                </div>
                <div className="flex  w-full flex-row gap-8">
                  <TextArea
                    label={t("contactMessage")}
                    onChange={handleMessageChanged}
                    value={messageValue}
                    rows={5}
                    id="message"
                    page={page}
                  />
                </div>
                <div className="flex w-full flex-row items-center gap-2">
                  <input
                    type="checkbox"
                    id="privacy"
                    checked={acceptPrivacy}
                    onChange={(e) => setAcceptPrivacy(e.target.checked)}
                    className="h-4 w-4"
                  />
                  <label htmlFor="privacy" className="text-sm">
                    <a
                      href="/privacyPolicy"
                      className="text-cobalt hover:underline"
                    >
                      {t("privacyPolicyConsent")}
                    </a>
                  </label>
                </div>
                <div className="relative flex  w-full flex-row items-end justify-end">
                  <div className="absolute left-10 top-0 font-semibold italic">
                    {t("requiredField")}
                  </div>
                  <div
                    className={` ${
                      buttonHover ? "text-white" : "text-peach"
                    }  border-2 border-peach ${
                      buttonHover
                        ? `bg-peach p-1 text-center ${getButtonTextDimens()}`
                        : `p-1 text-center ${getButtonTextDimens()}`
                    } transition-colors duration-300 ease-in-out`}
                    onMouseEnter={() => setButtonHover(true)}
                    onMouseLeave={() => setButtonHover(false)}
                  >
                    <button className="w-full px-10 py-1 font-semibold ">
                      {t("send")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
