import { useEffect, useRef, useState } from "react";
import GameOverLogo from "../../../../assets/logo_h.png";
import GameOverScreen from "../../../../components/game/GameOverScreen";
import GameBoard from "../../../../components/game/GameScreen";
import Paddle from "../../../../components/game/Paddle";
import Ball from "../../../../components/game/Ball";
import StartScreen from "../../../../components/game/GameStartScreen";
import Brick from "../../../../components/game/Brick";
import GameWinScreen from "../../../../components/game/GameWinScreen";
import E from "../../../../assets/everyBricks_E.svg";
import V from "../../../../assets/everyBricks_V.svg";
import R from "../../../../assets/everyBricks_R.svg";
import Y from "../../../../assets/everyBricks_Y.svg";
import Logo1 from "../../../../assets/everyBricks_Logo1.svg";
import Logo2 from "../../../../assets/everyBricks_Logo2.svg";
import B from "../../../../assets/everyBricks_B.svg";
import O from "../../../../assets/everyBricks_O.svg";
import T from "../../../../assets/everyBricks_T.svg";
import I from "../../../../assets/everyBricks_I.svg";
import C from "../../../../assets/everyBricks_C.svg";
import S from "../../../../assets/everyBricks_S.svg";
import useMediaQuery from "../../../../hooks/useMediaQuery";
import { Screens } from "../../../../shared/types";
import { useTranslation } from "react-i18next";

type Props = {};

const Game = ({}: Props) => {
  const [gameOver, setGameOver] = useState(false);
  const [score, setScore] = useState(0);
  const [lives, setLives] = useState(4);

  const [paddleX, setPaddleX] = useState(0);
  const [paddleY, setPaddleY] = useState(20);
  const gameBoardRef = useRef<HTMLDivElement>(null);
  const [isMovingLeft, setIsMovingLeft] = useState<boolean>(false);
  const [isMovingRight, setIsMovingRight] = useState<boolean>(false);

  const [gameStarted, setGameStarted] = useState(false);
  const [gameWon, setGameWon] = useState(false);

  const [brickWidth, setBrickWidth] = useState(0);
  const [brickHeight, setBrickHeight] = useState(0);

  const isAboveHugeScreen = useMediaQuery(Screens.Huge);
  const isAboveLargeScreen = useMediaQuery(Screens.Large);
  const isAboveMediumScreen = useMediaQuery(Screens.Medium);
  const isAboveSmallScreen = useMediaQuery(Screens.Small);

  var brickSize = 0;

  useEffect(() => {
    if (gameBoardRef.current) {
      const boardWidth = gameBoardRef.current.offsetWidth;
      if (isAboveHugeScreen) {
        brickSize = boardWidth / 12;
      } else if (isAboveLargeScreen) {
        brickSize = boardWidth / 15;
      } else if (isAboveMediumScreen || isAboveSmallScreen) {
        brickSize = boardWidth / 18;
      } else {
        brickSize = boardWidth / 36;
      }

      setBrickWidth(brickSize);
      setBrickHeight(brickSize);
    }
  }, [gameBoardRef, brickSize]);

  const handleStart = () => {
    if (gameBoardRef.current) {
      const boardWidth = gameBoardRef.current.offsetWidth;
      if (isAboveHugeScreen) {
        brickSize = boardWidth / 12;
      } else if (isAboveLargeScreen) {
        brickSize = boardWidth / 15;
      } else if (isAboveMediumScreen || isAboveSmallScreen) {
        brickSize = boardWidth / 18;
      } else {
        brickSize = boardWidth / 36;
      }

      setBrickWidth(brickSize);
      setBrickHeight(brickSize);
    }
    setGameStarted(true);
    setIsMovingLeft(false);
    setIsMovingRight(false);
  };

  const handleReset = () => {
    setGameOver(false);
    setGameWon(false);
    setLives(4);
    setGameStarted(true);
    setIsMovingLeft(false);
    setIsMovingRight(false);
  };

  useEffect(() => {
    const gameBoardHeight = gameBoardRef.current?.offsetHeight || 0;
    const gameBoardWidth = gameBoardRef.current?.offsetWidth || 0;
    const initialPaddleY = gameBoardHeight - 160;
    const initialPaddleX = gameBoardWidth / 2 - gameBoardWidth / 24;
    setPaddleY(initialPaddleY);
    setPaddleX(initialPaddleX);
  }, []);

  useEffect(() => {
    const updatePaddlePosition = () => {
      const gameBoardHeight = gameBoardRef.current?.offsetHeight || 0;
      const gameBoardWidth = gameBoardRef.current?.offsetWidth || 0;
      const initialPaddleY = gameBoardHeight - 160;
      const initialPaddleX = gameBoardWidth / 2 - gameBoardWidth / 24;
      setPaddleY(initialPaddleY);
      setPaddleX(initialPaddleX);
      return { x: initialPaddleX, y: initialPaddleY };
    };

    window.addEventListener("resize", updatePaddlePosition);

    return () => {
      window.removeEventListener("resize", updatePaddlePosition);
    };
  }, []);

  useEffect(() => {
    if (lives === 0) {
      setGameOver(true);
    }
  }, [lives]);

  const handleBallCollision = (
    collision: "brick" | "bottom" | "paddle" | "top" | "horizontal"
  ) => {
    if (collision === "brick") {
    } else if (collision === "bottom") {
      setLives((prevLives) => prevLives - 1);
    }
  };

  function selectColor(lives: number): string {
    if (lives >= 3) {
      return "text-white";
    } else {
      return "text-peach";
    }
  }

  function getMorePixels(): number {
    if (isAboveHugeScreen) {
      return 60;
    } else if (isAboveLargeScreen) {
      return 60;
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return 20;
    } else {
      return 20;
    }
  }

  function setSpeed(): number {
    if (isAboveHugeScreen) {
      return 5;
    } else if (isAboveLargeScreen) {
      return 5;
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return 3;
    } else {
      return 2;
    }
  }

  function getMarginTop(): String {
    if (isAboveHugeScreen) {
      return "mt-20";
    } else if (isAboveLargeScreen) {
      return "mt-20";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "mt-10";
    } else {
      return "mt-5";
    }
  }

  function getPageDimens(): String {
    if (isAboveHugeScreen) {
      return "h-[calc(100vh-90px-86px)]";
    } else if (isAboveLargeScreen) {
      return " h-[calc(100vh-90px-86px)]";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "h-[calc(100vh-60px-56px)]";
    } else {
      return "h-[calc(100vh-60px-56px)]";
    }
  }
  function getPageDimens2(): String {
    if (isAboveHugeScreen) {
      return "h-[calc(100vh-90px-86px-32px)]";
    } else if (isAboveLargeScreen) {
      return " h-[calc(100vh-90px-86px-32px)]";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "h-[calc(100vh-60px-56px-32px)]";
    } else {
      return "h-[calc(100vh-60px-56px-32px)]";
    }
  }

  const {t, i18n} = useTranslation();

  return (
    <div
      className={`gameBoard relative ${getPageDimens()} w-full snap-start bg-blue-black bg-cover bg-center flex items-center justify-center p-4 `}
      id="game"
      ref={gameBoardRef}
    >
      <div
        className={` relative ${getPageDimens2()}  w-full snap-start  bg-white bg-cover bg-center px-4  py-4`}
      >
        {gameStarted ? (
          !gameWon ? (
            gameOver ? (
              <GameOverScreen logo={GameOverLogo} onReset={handleReset} />
            ) : (
              <GameBoard>
                <div className="absolute right-[calc(120px)] top-[calc(60px)] text-xl font-semibold text-white">
                  {t("lives")}
                  <span className={`${selectColor(lives)} pl-2`}>
                    {" "}
                    {lives}{" "}
                  </span>
                </div>
                <Paddle
                  x={paddleX}
                  y={paddleY}
                  initialPosition={{
                    x: paddleX,
                    y: paddleY,
                    width: gameBoardRef.current?.offsetWidth || 0,
                    height: gameBoardRef.current?.offsetHeight || 0,
                  }}
                  isMovingLeft={isMovingLeft}
                  isMovingRight={isMovingRight}
                  setIsMovingLeft={setIsMovingLeft}
                  setIsMovingRight={setIsMovingRight}
                />
                <Ball
                  onCollision={handleBallCollision}
                  initialPosition={{
                    x: paddleX,
                    y: paddleY,
                    width: gameBoardRef.current?.offsetWidth || 0,
                    height: gameBoardRef.current?.offsetHeight || 0,
                  }}
                  vX={setSpeed()}
                  vY={setSpeed()}
                  setGameWon={setGameWon}
                />

                <div className="flex h-full w-full flex-col items-center justify-start">
                  <div
                    className={`flex h-1/4 w-full flex-row items-end justify-center gap-16 ${getMarginTop()}`}
                  >
                    <Brick
                      svgDataUrl={E}
                      width={brickWidth}
                      height={brickHeight}
                    />
                    <Brick
                      svgDataUrl={V}
                      width={brickWidth}
                      height={brickHeight}
                    />
                    <Brick
                      svgDataUrl={E}
                      width={brickWidth}
                      height={brickHeight}
                    />
                    <Brick
                      svgDataUrl={R}
                      width={brickWidth}
                      height={brickHeight}
                    />
                    <Brick
                      svgDataUrl={Y}
                      width={brickWidth}
                      height={brickHeight}
                    />
                    <Brick
                      svgDataUrl={Logo1}
                      width={brickWidth}
                      height={brickHeight + getMorePixels()}
                    />
                    <Brick
                      svgDataUrl={Logo2}
                      width={brickWidth + getMorePixels()}
                      height={brickHeight + getMorePixels()}
                    />
                  </div>
                  <div className="flex h-1/4 w-full flex-row items-end justify-center  gap-16 ">
                    <Brick
                      svgDataUrl={B}
                      width={brickWidth}
                      height={brickHeight}
                    />
                    <Brick
                      svgDataUrl={O}
                      width={brickWidth}
                      height={brickHeight}
                    />
                    <Brick
                      svgDataUrl={T}
                      width={brickWidth}
                      height={brickHeight}
                    />
                    <Brick
                      svgDataUrl={I}
                      width={brickWidth}
                      height={brickHeight}
                    />
                    <Brick
                      svgDataUrl={C}
                      width={brickWidth}
                      height={brickHeight}
                    />
                    <Brick
                      svgDataUrl={S}
                      width={brickWidth}
                      height={brickHeight}
                    />
                  </div>
                </div>
              </GameBoard>
            )
          ) : (
            <GameWinScreen logo={GameOverLogo} onReset={handleReset} />
          )
        ) : (
          <StartScreen onStart={handleStart} logo={GameOverLogo} />
        )}
      </div>
    </div>
  );
};

export default Game;
