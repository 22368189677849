import AnchorLink from "react-anchor-link-smooth-scroll";
import { SelectedPage } from "../../shared/types";
import AnimatedOverline from "../../components/AnimatedOverline";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

type Props = {
  page: string;
  selectedPage: SelectedPage;
  setSelectedPage: (value: SelectedPage) => void;
};

const LinkItem = ({ page, selectedPage, setSelectedPage }: Props) => {
  const lowerCasePage = page
    .toLocaleLowerCase()
    .replace(/ /g, "") as SelectedPage;

  const { t, i18n } = useTranslation();

  return (
    <NavLink
      to={`/${lowerCasePage}`}
      className={`
     font-semibold decoration-peach decoration-4 whitespace-nowrap
        `}
      onClick={() => {
        setSelectedPage(lowerCasePage);
      }}
    >
      <AnimatedOverline clicked={selectedPage === lowerCasePage}>
        {t(page)}
      </AnimatedOverline>
    </NavLink>
  );
};

export default LinkItem;
