import { Particles } from "react-tsparticles";
import { useCallback } from "react";
import { loadFull } from "tsparticles";
import { Container, Engine } from "tsparticles-engine";
import LogoTransp from "../assets/banner_logo.png";

type Props = {};

const MouseParticles = ({}: Props) => {
  const particlesLoaded = useCallback(
    async (container: Container | undefined) => {},
    []
  );

  const particlesInit = useCallback(async (engine: Engine) => {
    await loadFull(engine);
  }, []);

  return (
    <Particles
      className="h-[calc(100vh-60px-56px)] w-full "
      id="tsparticles"
      init={particlesInit}
      loaded={particlesLoaded}
      options={{
        fpsLimit: 60,
        fullScreen: {
          enable: false,
          zIndex: -1,
        },

        particles: {
          number: {
            value: 0,
            density: {
              enable: true,
              value_area: 800,
            },
          },

          color: {
            value: ["#004aad", "#f8b45b"],
          },
          shape: {
            type: "image",
            image: {
              src: LogoTransp,
              width: 200,
              height: 118,
            },
            stroke: {
              width: 1,
              color: "#f8b45b",
            },
          },

          opacity: {
            value: 1,
            random: false,
            anim: {
              enable: false,
              speed: 3,
              opacity_min: 0.1,
              sync: false,
            },
          },
          size: {
            value: 50,
            random: {
              enable: false,
              minimumValue: 10,
            },
            animation: {
              enable: true,
              speed: 5,
              minimumValue: 5,
              sync: true,
              startValue: "min",
              destroy: "max",
            },
          },
          links: {
            color: "#f8b45b",
            distance: 50,
            enable: true,
            opacity: 0.5,
            width: 1,
          },
          move: {
            enable: true,
            speed: 3.5,
            direction: "none",
            random: false,
            straight: false,
            outMode: "destroy",
            attract: {
              enable: false,
              rotateX: 600,
              rotateY: 1200,
            },
          },
        },
        interactivity: {
          detectsOn: "window",
          events: {
            onhover: {
              enable: true,
              mode: "trail",
            },
            onclick: {
              enable: true,
              mode: "push",
            },
            resize: true,
          },
          modes: {
            grab: {
              distance: 400,
              line_linked: {
                opacity: 1,
              },
            },
            bubble: {
              distance: 400,
              size: 40,
              duration: 2,
              opacity: 0.8,
              speed: 3,
            },
            repulse: {
              distance: 200,
            },
            push: {
              particles_nb: 10,
            },
            remove: {
              particles_nb: 2,
            },
            trail: {
              delay: 0.005,
              quantity: 5,
              pauseOnStop: true,
            },
          },
        },
        retina_detect: true,
      }}
      style={{ position: "fixed" }}
    />
  );
};

export default MouseParticles;
