import { useEffect, useRef, useState } from "react";
import ScrollToTopButton from "../../../components/ScrollToTopButton";
import Home from "./components/home";
import About from "./components/about";
import MouseParticles from "../../../components/MouseParticles";
import OurServices from "./components/ourServices";
import {
  Screens,
  SelectedPage,
  SelectedService,
  services,
} from "../../../shared/types";
import { useCallback } from "react";
import { useDebounce } from "use-debounce";
import SpinOff from "./components/spinoff";
import Team from "./components/team";
import Game from "./components/game";
import ContactUs from "./components/contactUs";
import MapCassino from "./components/map";
import useMediaQuery from "../../../hooks/useMediaQuery";
import AboutMobile from "./mobile/aboutMobile";
import OurServicesMobile from "./mobile/ourServicesMobile";
import CodingContent from "../../servicesMenu/codingContent";
import CodingContentMobile from "../../servicesMenuMobile/codingContentMobile";
import AIContentMobile from "../../servicesMenuMobile/aiContentMobile";
import RoboticsContent from "../../servicesMenu/roboticsContent";
import RoboticsContentMobile from "../../servicesMenuMobile/roboticsContentMobile";
import WebSitesContentMobile from "../../servicesMenuMobile/websitesContentMobile";
import ServicesContentMobile from "../../servicesMenuMobile/serviceContentMobile";
import CyberContentMobile from "../../servicesMenuMobile/cybersecurityContentMobile";
import TeamMobile from "./mobile/teamMobile";
import TeamMobile2 from "./mobile/teamMobile2";
import TeamMobile3 from "./mobile/teamMobile3";
import TeamMobile4 from "./mobile/teamMobile4";
import ContactUsMobile from "./mobile/contactUsMobile";
import SpinOffMobile from "./mobile/spinoffMobile";

type Props = {
  buttonHover: boolean;
  setButtonHover: (value: boolean) => void;

  isMenuToggled: boolean;
  setIsMenuToggled: (value: boolean) => void;

  selectedDiv: String;
  setSelectedDiv: (value: String) => void;

  previousDiv: String;
  setPreviousDiv: (value: String) => void;

  setSelectedPage(value: SelectedPage): void;
};

const HomePage = ({
  buttonHover,
  setButtonHover,
  isMenuToggled,
  setIsMenuToggled,
  selectedDiv,
  setSelectedDiv,
  previousDiv,
  setPreviousDiv,
  setSelectedPage,
}: Props) => {
  const isAboveSmallcreens = useMediaQuery(Screens.Small);
  const ref = useRef<HTMLDivElement>(null);

  const scrollToRef = () => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [selectedService, setSelectedService] = useState<SelectedService>(
    SelectedService.Coding
  );

  const isAboveHugeScreen = useMediaQuery(Screens.Huge);
  const isAboveLargeScreen = useMediaQuery(Screens.Large);
  const isAboveMediumScreen = useMediaQuery(Screens.Medium);

  function getPageDimens(): String {
    if (isAboveHugeScreen) {
      return " h-[calc(100vh-90px -90px)]";
    } else if (isAboveLargeScreen) {
      return " h-[calc(100vh-90px -90px)]";
    } else if (isAboveMediumScreen || isAboveSmallcreens) {
      return " h-[calc(100vh-60px -60px)]";
    } else {
      return " h-[calc(100vh-60px -60px)]";
    }
  }

  const aboutRef = useRef<HTMLDivElement>(null);

  const scrollToAbout = () => {
    if (aboutRef.current) {
      aboutRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <main
      style={{ padding: 0, margin: 0, boxSizing: "border-box" }}
      className={`no-scrollbar  relative flex-1 snap-y snap-mandatory  ${getPageDimens()}  overflow-y-auto overflow-x-hidden `}
    >
      <div
        className=""
        id="home"
        onMouseEnter={() => {
          setPreviousDiv(selectedDiv);
          setSelectedDiv("home");
        }}
        ref={ref}
      >
        <Home
          buttonHover={buttonHover}
          setButtonHover={setButtonHover}
          selectedDiv={selectedDiv}
          scrollToAbout={scrollToAbout}
        />
      </div>
      <div
        id="about"
        ref={aboutRef}
        onMouseEnter={() => {
          setPreviousDiv(selectedDiv);
          setSelectedDiv("about");
        }}
      >
        {isAboveSmallcreens ? (
          <About selectedDiv={selectedDiv} setSelectedPage={setSelectedPage} />
        ) : (
          <AboutMobile
            selectedDiv={selectedDiv}
            setSelectedPage={setSelectedPage}
          />
        )}
      </div>
      <div
        onMouseEnter={() => {
          setPreviousDiv(selectedDiv);
          setSelectedDiv("ourServices");
        }}
        id="ourServices"
      >
        {" "}
        {isAboveSmallcreens ? (
          <OurServices
            selectedDiv={selectedDiv}
            selectedService={selectedService}
            setSelectedService={setSelectedService}
            setSelectedPage={setSelectedPage}
          />
        ) : (
          <CodingContentMobile />
        )}
        {!isAboveSmallcreens && <AIContentMobile />}
        {!isAboveSmallcreens && <RoboticsContentMobile />}
        {!isAboveSmallcreens && <WebSitesContentMobile />}
        {!isAboveSmallcreens && <CyberContentMobile />}
        {!isAboveSmallcreens && <ServicesContentMobile />}
      </div>
      <div
        onMouseEnter={() => {
          setPreviousDiv(selectedDiv);
          setSelectedDiv("spinOff");
        }}
        id="spinOff"
      >
        {" "}
        {isAboveSmallcreens ? <SpinOff /> : <SpinOffMobile />}
      </div>
      {/*<div
        onMouseEnter={() => {
          setPreviousDiv(selectedDiv);
          setSelectedDiv("team");
        }}
        id="team"
      >
        {" "}
        {isAboveSmallcreens ? (
          <Team setSelectedPage={setSelectedPage} />
        ) : (
          <TeamMobile setSelectedPage={setSelectedPage} />
        )}
        {!isAboveSmallcreens && (
          <TeamMobile2 setSelectedPage={setSelectedPage} />
        )}
        {!isAboveSmallcreens && (
          <TeamMobile3 setSelectedPage={setSelectedPage} />
        )}
        {!isAboveSmallcreens && (
          <TeamMobile4 setSelectedPage={setSelectedPage} />
        )}
        </div>*/}
      {isAboveSmallcreens && (
        <div
          onMouseEnter={() => {
            setPreviousDiv(selectedDiv);
            setSelectedDiv("game");
          }}
          id="game"
        >
          <Game />
        </div>
      )}
      <div
        onMouseEnter={() => {
          setPreviousDiv(selectedDiv);
          setSelectedDiv("contactus");
        }}
        id="contactus"
      >
        {isAboveSmallcreens ? (
          <ContactUs page={false} />
        ) : (
          <ContactUsMobile page={false} />
        )}
      </div>
      <div
        onMouseEnter={() => {
          setPreviousDiv(selectedDiv);
          setSelectedDiv("map");
        }}
        id="map"
      >
        <MapCassino />
      </div>
      <div
        className={`${
          selectedDiv === "home" || isMenuToggled ? "opacity-0" : "opacity-100"
        } transition-opacity duration-300 ease-in-out `}
        onClick={scrollToRef}
      >
        <ScrollToTopButton />
      </div>
    </main>
  );
};

export default HomePage;
