import React, { useState } from "react";
import BannerLight from "../assets/banner_light.png";
import Back1 from "../assets/back3.png";
import useMediaQuery from "../hooks/useMediaQuery";
import { Screens } from "../shared/types";

const MouseCircleEffect: React.FC = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    setMousePosition({ x: e.clientX, y: e.clientY });
  };

  const isAboveHugeScreen = useMediaQuery(Screens.Huge);
  const isAboveLargeScreen = useMediaQuery(Screens.Large);
  const isAboveMediumScreen = useMediaQuery(Screens.Medium);
  const isAboveSmallScreen = useMediaQuery(Screens.Small);

  function getCircleDimens(): String {
    if (isAboveHugeScreen) {
      return "600px";
    } else if (isAboveLargeScreen) {
      return "600px";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "250px";
    } else {
      return "100px";
    }
  }

  return (
    <div className="relative h-screen bg-white" onMouseMove={handleMouseMove}>
      <div
        className="absolute left-0 top-0 h-full w-full bg-cover bg-center"
        style={{
          backgroundImage: `url(${Back1})`,
          clipPath: `circle(${getCircleDimens()} at ${mousePosition.x -200}px ${mousePosition.y -100}px)`,
          pointerEvents: "none",
        }}
      />
    </div>
  );
};

export default MouseCircleEffect;
