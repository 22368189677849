import { NavLink } from "react-router-dom";
import ScrollToTopButton from "../../components/ScrollToTopButton";
import useMediaQuery from "../../hooks/useMediaQuery";
import { Screens, SelectedPage, SelectedSnap } from "../../shared/types";

type Props = {
  selectedDiv: String;
  setSelectedPage: (value: SelectedPage) => void;
};

const Footer = ({ selectedDiv, setSelectedPage }: Props) => {
  const isAboveHugeScreen = useMediaQuery(Screens.Huge);
  const isAboveLargeScreen = useMediaQuery(Screens.Large);
  const isAboveMediumScreen = useMediaQuery(Screens.Medium);
  const isAboveSmallScreen = useMediaQuery(Screens.Small);

  function getPIVADimens(): String {
    if (isAboveHugeScreen) {
      return "text-base";
    } else if (isAboveLargeScreen) {
      return "text-base";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "text-base";
    } else {
      return "text-sm";
    }
  }
  function getPolicyDimens(): String {
    if (isAboveHugeScreen) {
      return "text-sm";
    } else if (isAboveLargeScreen) {
      return "text-sm";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "text-sm";
    } else {
      return "text-xs";
    }
  }

  function getHeight(): String {
    if (isAboveHugeScreen) {
      return " h-whiteBorderXL";
    } else if (isAboveLargeScreen) {
      return "h-whiteBorderL";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "h-whiteBorderM";
    } else {
      return "h-whiteBorderS";
    }
  }

  return (
    <footer className={`z-40 h-[calc(60px)] bg-white ${getHeight()}`}>
      <div
        className={`flex  flex-row items-center justify-center  ${getHeight()} ${
          selectedDiv == "map" ||
          selectedDiv == "secondPage" ||
          selectedDiv == "teamPage" ||
          selectedDiv == "aboutPage" ||
          selectedDiv == "contactPage"
            ? "opacity-100"
            : "opacity-0"
        } transition-opacity duration-300 ease-in-out`}
      >
        <div className={`font-semibold ${getPIVADimens()}`}>
          P.IVA 03130710605
        </div>
        <NavLink
          className={`absolute right-[calc(60px)] ${getPolicyDimens()}`}
          to={`/privacyPolicy`}
          onClick={() => {
            setSelectedPage(SelectedPage.Privacy);
          }}
        >
          <button>Privacy Policy</button>
        </NavLink>
      </div>
    </footer>
  );
};

export default Footer;
