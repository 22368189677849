import { useCallback, useRef } from "react";
import FirstPage from "../../../components/firstPage";
import ContentPage from "../../../components/page";
import ScrollToTopButton from "../../../components/ScrollToTopButton";
import Pic from "../../../assets/everybot.png";
import Team from "../home/components/team";
import ContactUs from "../home/components/contactUs";
import useMediaQuery from "../../../hooks/useMediaQuery";
import { Screens } from "../../../shared/types";
import ContactUsMobile from "../home/mobile/contactUsMobile";

type Props = {
  selectedDiv: String;
  setSelectedDiv: (value: String) => void;
  isMenuToggled: boolean;
  setIsMenuToggled: (value: boolean) => void;
};

const ContactPage = ({
  selectedDiv,
  setSelectedDiv,
  isMenuToggled,
  setIsMenuToggled,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  const scrollToRef = () => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const isAboveMediumScreens = useMediaQuery(Screens.Small);
  const isAboveHugeScreen = useMediaQuery(Screens.Huge);
  const isAboveLargeScreen = useMediaQuery(Screens.Large);
  const isAboveMediumScreen = useMediaQuery(Screens.Medium);
  const isAboveSmallScreen = useMediaQuery(Screens.Small);

  function getPageDimens(): String {
    if (isAboveHugeScreen) {
      return "h-[calc(100vh-180px)]]";
    } else if (isAboveLargeScreen) {
      return " h-[calc(100vh-180px)]";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return " h-[calc(100vh-120px)]";
    } else {
      return "h-[calc(100vh-120px)]]";
    }
  }

  return (
    <main
      id="projects"
      style={{ padding: 0, margin: 0, boxSizing: "border-box" }}
      className={`relative ${getPageDimens()} w-full snap-start overflow-hidden bg-cover bg-center bg-no-repeat p-[calc(60px)]`}
    >
      <div
        onMouseEnter={() => {
          setSelectedDiv("contactPage");
        }}
        id="contactPage"
      >
        {isAboveMediumScreens ? (
          <ContactUs page={true} />
        ) : (
          <ContactUsMobile page={true} />
        )}
      </div>
    </main>
  );
};

export default ContactPage;
