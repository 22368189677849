import React, { useState } from "react";
import { useTranslation } from "react-i18next";

interface GameOverScreenProps {
  onReset: () => void;
  logo: string;
}

const GameWinScreen = ({ logo, onReset }: GameOverScreenProps) => {
  const [buttonHover, setButtonHover] = useState<boolean>(false);

  const { t, i18n } = useTranslation();

  return (
    <div className="flex h-full flex-col items-center justify-center bg-blue-black text-white">
      <img src={logo} alt="Logo" className="mb-8 w-3/4" />
      <h1 className="mb-8 text-4xl font-bold">{t("win")}</h1>
      <div
        className={` ${
          buttonHover ? "text-white" : "text-peach"
        }  border-2 border-peach ${
          buttonHover
            ? "bg-peach p-1 text-center text-2xl "
            : "p-1 text-center text-2xl"
        } transition-colors duration-300 ease-in-out`}
        onMouseEnter={() => setButtonHover(true)}
        onMouseLeave={() => setButtonHover(false)}
      >
        <button className="w-full px-10 py-1 font-semibold " onClick={onReset}>
          {t("playAgain")}
        </button>
      </div>
    </div>
  );
};

export default GameWinScreen;
