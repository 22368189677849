import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ArrowUp from "../assets/arrow_up.png";
import useMediaQuery from "../hooks/useMediaQuery";
import { Screens } from "../shared/types";

type Props = {};

const ScrollToTopButton = ({}: Props) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };


  const isAboveHugeScreen = useMediaQuery(Screens.Huge);
  const isAboveLargeScreen = useMediaQuery(Screens.Large);
  const isAboveMediumScreen = useMediaQuery(Screens.Medium);
  const isAboveSmallScreen = useMediaQuery(Screens.Small);

  function getWidth(): String {
    if (isAboveHugeScreen) {
      return " w-whiteBorderXL";
    } else if (isAboveLargeScreen) {
      return " w-whiteBorderL";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "w-whiteBorderM";
    } else {
      return " w-whiteBorderS";
    }
  }

  function getHeight(): String {
    if (isAboveHugeScreen) {
      return " h-whiteBorderXL";
    } else if (isAboveLargeScreen) {
      return " h-whiteBorderL";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "h-whiteBorderM";
    } else {
      return " h-whiteBorderS";
    }
  }

  return (
    <button
      className={`fixed bottom-0 left-0 z-50 ${getHeight()} ${getWidth()} bg-cobalt p-4 transition-all duration-300 ease-in-out`}
      onClick={scrollToTop}
    >
      <img src={ArrowUp} alt="" />
    </button>
  );
};

export default ScrollToTopButton;
