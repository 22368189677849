import { useState } from "react";
import useMediaQuery from "../../../hooks/useMediaQuery";
import { Screens } from "../../../shared/types";
import { useTranslation } from "react-i18next";

type Props = {
  pageContent: JSX.Element;
  pic: string;
  index: number;
  name: string;
  setModalIsOpen: (value: boolean) => void;
};

const ContentPage = ({
  pageContent,
  pic,
  index,
  name,
  setModalIsOpen,
}: Props) => {
  const [buttonHover, setButtonHover] = useState<boolean>(false);

  function selectColor(): string {
    return index % 2 == 0 ? "bg-peach" : "bg-cobalt";
  }

  function selectColorButtonBackground(): string {
    return index % 2 == 0 ? "bg-cobalt" : "bg-peach";
  }

  function selectColorButtonBorder(): string {
    return index % 2 == 0 ? "border-cobalt" : "border-peach";
  }

  function selectColorButtonText(): string {
    return index % 2 == 0 ? "text-cobalt" : "text-peach";
  }

  const isAboveHugeScreen = useMediaQuery(Screens.Huge);
  const isAboveLargeScreen = useMediaQuery(Screens.Large);
  const isAboveMediumScreen = useMediaQuery(Screens.Medium);
  const isAboveSmallScreen = useMediaQuery(Screens.Small);

  function getTextDimens(): String {
    if (isAboveHugeScreen) {
      return "text-2xl";
    } else if (isAboveLargeScreen) {
      return "text-2xl";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "text-sm";
    } else {
      return "text-xs";
    }
  }

  const {t, i18n} = useTranslation();

  return (
    <div className="relative   flex h-full w-3/4 flex-col items-center justify-center ">
      <div className="flex h-full w-full flex-col items-center justify-center gap-6 py-[calc(40px)]">
        <div className="w-full text-justify ">
          <div className="grid w-full grid-cols-2 gap-[calc(120px)]  ">
            <div
              className={`col-span-1 flex flex-col items-center justify-center ${getTextDimens()} `}
            >
              <div
                className={`flex h-full w-full flex-col items-center justify-center gap-4 border-8 border-solid border-white px-8 py-8`}
              >
                <div
                  className="brightness-1100 relative w-full contrast-100 saturate-150 filter"
                  style={{ paddingBottom: "100%" }}
                >
                  <div
                    className="absolute inset-0 bg-cover bg-center"
                    style={{ backgroundImage: `url(${pic})` }}
                  ></div>
                  <div
                    className={`absolute inset-0 ${selectColor()} opacity-70`}
                    style={{ mixBlendMode: "multiply" }}
                  ></div>
                </div>{" "}
                <div className="flex  flex-col items-center justify-start gap-0">
                  <div
                    className={`${getTextDimens()} font-semibold text-white`}
                  >
                    {name}
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`col-span-1  flex flex-col items-start justify-center ${getTextDimens()} `}
            >
              {pageContent}
              <div
                className={` ${
                  buttonHover ? "text-white" : `${selectColorButtonText()}`
                } border-2 ${selectColorButtonBorder()} ${
                  buttonHover
                    ? `${selectColorButtonBackground()} p-1 text-center ${getTextDimens()}`
                    : `p-1 text-center ${getTextDimens()}`
                } transition-colors duration-300 ease-in-out`}
                onMouseEnter={() => setButtonHover(true)}
                onMouseLeave={() => setButtonHover(false)}
              >
                <button
                  className="w-full px-10 py-1 font-semibold "
                  onClick={() => setModalIsOpen(false)}
                >
                  {t("close")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentPage;
