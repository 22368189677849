import { useTranslation } from "react-i18next";
import useMediaQuery from "../../hooks/useMediaQuery";
import { Screens, SelectedService } from "../../shared/types";
import ServiceContent from "./serviceContent";

type Props = {
  service: string;
  selectedService: SelectedService;
  setSelectedService: (value: SelectedService) => void;
};

const Items = ({ service, selectedService, setSelectedService }: Props) => {
  function switchColor(): String {
    switch (service) {
      case SelectedService.Coding.toUpperCase().replace(/ /g, ""):
        return "bg-steel text-midnight";

      case SelectedService.AI.toUpperCase().replace(/ /g, ""):
        return "bg-peach text-cobalt";

      case SelectedService.Robotics.toUpperCase().replace(/ /g, ""):
        return "bg-cobalt text-white";

      case SelectedService.WebSites.toUpperCase().replace(/ /g, ""):
        return "bg-midnight text-white";
      
      case SelectedService.Cybersecurity.toUpperCase().replace(/ /g, ""):
        return "bg-blue-black text-white";

      case SelectedService.Services.toUpperCase().replace(/ /g, ""):
        return "bg-black text-white";
    }
    return "";
  }

  function switchServiceNumber(): String {
    switch (service) {
      case SelectedService.Coding.toUpperCase().replace(/ /g, ""):
        return "01";

      case SelectedService.AI.toUpperCase().replace(/ /g, ""):
        return "02";

      case SelectedService.Robotics.toUpperCase().replace(/ /g, ""):
        return "03";

      case SelectedService.WebSites.toUpperCase().replace(/ /g, ""):
        return "04";

      case SelectedService.Cybersecurity.toUpperCase().replace(/ /g, ""):
        return "05";

      case SelectedService.Services.toUpperCase().replace(/ /g, ""):
        return "06";
    }
    return "";
  }

  const lowerCasePage = service
    .toLocaleLowerCase()
    .replace(/ /g, "") as SelectedService;

  const isAboveHugeScreen = useMediaQuery(Screens.Huge);
  const isAboveLargeScreen = useMediaQuery(Screens.Large);
  const isAboveMediumScreen = useMediaQuery(Screens.Medium);
  const isAboveSmallScreen = useMediaQuery(Screens.Small);

  function getTitleDimens(): String {
    if (isAboveHugeScreen) {
      return "text-4xl";
    } else if (isAboveLargeScreen) {
      return "text-4xl";
    } else if (isAboveMediumScreen) {
      return "text-2xl";
    } else if (isAboveSmallScreen) {
      return "text-lg";
    } else {
      return "text-lg";
    }
  }

  function getStringDimens(): String {
    if (isAboveHugeScreen) {
      return "text-4xl";
    } else if (isAboveLargeScreen) {
      return "text-4xl";
    } else if (isAboveMediumScreen) {
      return "text-xl";
    } else if (isAboveSmallScreen) {
      return "text-xs";
    } else {
      return "text-sm";
    }
  }

  function getHeight(): String {
    if (isAboveHugeScreen) {
      return " h-whiteBorderXL";
    } else if (isAboveLargeScreen) {
      return " h-whiteBorderL";
    } else if (isAboveMediumScreen) {
      return "h-whiteBorderM";
    } else if (isAboveSmallScreen) {
      return "h-whiteBorderS";
    } else {
      return " h-whiteBorderS";
    }
  }

  const {t, i18n} = useTranslation();

  return (
    <div
      className={`
      transition-height flex w-full flex-col
      items-center    overflow-hidden p-6 duration-500  ease-out ${switchColor()} ${
        selectedService === lowerCasePage
          ? "h-full justify-start"
          : `${getHeight()} justify-center`
      }`}
      onClick={() => setSelectedService(lowerCasePage)}
    >
      <div className={`flex flex-row ${getTitleDimens()} font-semibold`}>
        <div className="absolute left-6 ">{switchServiceNumber()}</div>
        {t(service)}
      </div>
      <div
        className={` flex flex-col items-center justify-center ${
          selectedService === lowerCasePage ? "h-full w-full" : "hidden"
        } ${getStringDimens()}`}
      >
        <ServiceContent selectedService={selectedService} />
      </div>
    </div>
  );
};

export default Items;
