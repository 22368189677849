// ServiceSection.tsx
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useMediaQuery from "../../../hooks/useMediaQuery";
import { Screens, SelectedPage } from "../../../shared/types";
import { NavLink } from "react-router-dom";
import YellowLine from "../../../assets/yellow_line.png";

type Props = {
  title: string;
  description: string;
  imageUrl: string;
  setSelectedPage(value: SelectedPage): void;
};

const ServiceSection = ({
  title,
  description,
  imageUrl,
  setSelectedPage,
}: Props) => {
  const isAboveHugeScreen = useMediaQuery(Screens.Huge);
  const isAboveLargeScreen = useMediaQuery(Screens.Large);
  const isAboveMediumScreen = useMediaQuery(Screens.Medium);
  const isAboveSmallScreen = useMediaQuery(Screens.Small);
  const [buttonHover, setButtonHover] = useState<boolean>(false);
  const { t, i18n } = useTranslation();

  function getButtonTextDimens(): String {
    if (isAboveHugeScreen) {
      return "text-3xl";
    } else if (isAboveLargeScreen) {
      return "text-3xl";
    } else if (isAboveMediumScreen) {
      return "text-xl";
    } else if (isAboveSmallScreen) {
      return "text-base";
    } else {
      return "text-base";
    }
  }

  function getPageDimens(): String {
    if (isAboveHugeScreen) {
      return "h-[calc(100vh-180px)]]";
    } else if (isAboveLargeScreen) {
      return " h-[calc(100vh-180px)]";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return " h-[calc(100vh-120px)]";
    } else {
      return "h-[calc(100vh-120px)]]";
    }
  }

  function getTitleDimens(): String {
    if (isAboveHugeScreen) {
      return "text-8xl";
    } else if (isAboveLargeScreen) {
      return "text-8xl";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "text-6xl";
    } else {
      return "text-4xl";
    }
  }

  function getSubtitleDimens(): String {
    if (isAboveHugeScreen) {
      return "text-6xl";
    } else if (isAboveLargeScreen) {
      return "text-6xl";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "text-4xl";
    } else {
      return "text-2xl";
    }
  }

  function getStringDimens(): String {
    if (isAboveHugeScreen) {
      return "text-2xl";
    } else if (isAboveLargeScreen) {
      return "text-2xl";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "text-xl";
    } else {
      return "text-lg";
    }
  }

  function getTextWidth(): String {
    if (isAboveHugeScreen) {
      return "w-3/6";
    } else if (isAboveLargeScreen) {
      return "w-3/6";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "w-3/6";
    } else {
      return "w-3/6";
    }
  }

  function getLineMargin(): String {
    if (isAboveHugeScreen) {
      return "-ml-[calc(100px)]";
    } else if (isAboveLargeScreen) {
      return "-ml-[calc(100px)]";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "-ml-[calc(60px)]";
    } else {
      return "-ml-[calc(40px)]";
    }
  }

  return (
    <div
      className={`relative ${getPageDimens()} w-full snap-start bg-cover bg-center flex items-start `}
      id="about"
    >
      <div className="relative flex h-full w-1/2 flex-col items-center justify-start">
        <div className="flex h-full w-full items-center justify-start">
          <div
            className={`flex ml-[calc(20px)]  flex-col justify-center gap-10 w-full mr-[calc(60px)]`}
          >
            <div
              className={`text-left ${getTitleDimens()} font-semibold text-cobalt w-full`}
            >
              {title}
            </div>
            <div className={`${getLineMargin()} }`}>
              <img src={YellowLine} alt="" className="-my-4 h-3 w-1/6" />
            </div>

            <div className={` text-left ${getStringDimens()}`}>
              {description}
            </div>
            <div
              className={` w-full ${
                buttonHover ? "text-white" : "text-peach"
              } border-2 border-peach ${
                buttonHover
                  ? `bg-peach p-1 text-center ${getButtonTextDimens()} `
                  : `p-1 text-center ${getButtonTextDimens()} `
              } transition-colors duration-300 ease-in-out`}
              onMouseEnter={() => setButtonHover(true)}
              onMouseLeave={() => setButtonHover(false)}
            >
              <NavLink
                to={`/contact`}
                onClick={() => {
                  setSelectedPage(SelectedPage.Contact);
                }}
              >
                <button className="w-full px-10 py-1 font-semibold ">
                  {t("contactUs")}
                </button>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <div className="w-1/2 h-full flex justify-center items-center ">
        <img
          src={imageUrl}
          alt={title}
          className="absolute w-1/2  h-full object-cover"
        />{" "}
      </div>
    </div>
  );
};

export default ServiceSection;
