import { Screens, marker } from "../../../../shared/types";
import { useCallback, useMemo, useState } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import LogoMarker from "../../../../assets/circle_white_logo.png";
import { InfoWindow } from "@react-google-maps/api";

import mapStyle from "../../../../shared/mapStyle";
import useMediaQuery from "../../../../hooks/useMediaQuery";

import Logo from "../../../../assets/logo_transp_512.png";
import Map from "../../../../assets/map.png";

const MapCassino = () => {
  const center = useMemo(() => ({ lat: marker.lat, lng: marker.lng }), []);

  const [map, setMap] = useState<google.maps.Map | null>(null);

  const onLoad = useCallback(function callback(map: google.maps.Map) {
    setMap(map);
  }, []);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCibe7rXClSHFMNVsdv3rAWNvZ7WCBz04k",
  });

  const [infoWindowOpen, setInfoWindowOpen] = useState<boolean>(false);

  const isAboveHugeScreen = useMediaQuery(Screens.Huge);
  const isAboveLargeScreen = useMediaQuery(Screens.Large);
  const isAboveMediumScreen = useMediaQuery(Screens.Medium);
  const isAboveSmallScreens = useMediaQuery(Screens.Small);

  function getPageDimens(): String {
    if (isAboveHugeScreen) {
      return "h-[calc(100vh-180px)]]";
    } else if (isAboveLargeScreen) {
      return " h-[calc(100vh-180px)]";
    } else if (isAboveMediumScreen || isAboveSmallScreens) {
      return " h-[calc(100vh-120px)]";
    } else {
      return "h-[calc(100vh-120px)]]";
    }
  }

  const handleMarkerClick = useCallback(() => {
    setInfoWindowOpen((prevState) => !prevState);
  }, []);

  const cobaltHex = "#004aad";
  const cobaltHexClear = "#285FB2";
  const cobaltVeryClear = "#346EBC";
  const midnightHex = "#051537";
  const whiteHex = "#FFFFFF";
  const blueBlackHex = "#08081b";
  const cobaltRelief = "#14419A";

  const customMapStyle = [
    {
      featureType: "landscape",
      elementType: "geometry",
      stylers: [{ color: cobaltHexClear }],
    },
    {
      featureType: "landscape.natural",
      elementType: "geometry",
      stylers: [{ color: cobaltHex }],
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [{ color: cobaltHexClear }],
    },
    {
      featureType: "poi",
      elementType: "labels",
      stylers: [{ color: midnightHex }],
    },

    {
      featureType: "poi",
      elementType: "labels.text.stroke",
      stylers: [{ color: whiteHex }], // Set font weight to normal (not bold)
    },
    {
      featureType: "landscape",
      elementType: "labels",
      stylers: [{ color: midnightHex }],
    },

    {
      featureType: "landscape",
      elementType: "labels.text.stroke",
      stylers: [{ color: whiteHex }], // Set font weight to normal (not bold)
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [{ color: cobaltVeryClear }],
    },

    {
      featureType: "road",
      elementType: "labels",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "transit",
      elementType: "geometry",
      stylers: [{ color: cobaltVeryClear }, { strokeColor: midnightHex }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [{ color: midnightHex }],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [{ color: cobaltHex }],
    },

    // Add other style rules as needed...
  ];

  return (
    <div
      className={`relative flex ${
        isAboveSmallScreens
          ? `${getPageDimens()}`
          : "h-screen overflow-hidden pb-[calc(60px)]"
      } w-full snap-start items-center justify-center bg-white`}
      id="map"
    >
      <div className="max-w-9xl relative mx-auto grid grid-rows-4 md:grid-rows-1 grid-cols-1 gap-4 md:grid-cols-7">
        {/* Immagine della mappa occupa i due terzi dello spazio */}
        <div className="relative md:col-span-5 row-span-3 md:row-span-1">
          <img
            src={Map}
            alt="Map Location"
            className="h-full w-full rounded-xl object-cover shadow-lg"
          />
          {/* Dettagli mappa o testo sovrapposto */}
          <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-70 p-4 text-center">
            <h1 className="text-lg font-bold text-white">EveryBotics Srl</h1>
            <p className="text-white">Via G. Di Biasio, 43</p>
            <p className="text-white">03043 Cassino (FR), Italia</p>
          </div>
        </div>

        {/* Logo e dettagli aziendali nella colonna più piccola */}
        <div className="flex flex-col items-center justify-center rounded-xl bg-white p-4 shadow-lg md:col-span-2">
          <img
            src={Logo}
            alt="Company Logo"
            className="mb-4 h-auto w-32 md:w-48"
          />
          <div className="text-center">
            <h2 className="mb-2 text-lg font-semibold text-cobalt">
              EveryBotics Srl
            </h2>
            <p className="text-gray-700">
              Innovazione e Tecnologia per il Futuro
            </p>
          </div>
        </div>
      </div>
    </div>
  );
  /*return {
    
     <div
      className={`relative  flex ${
        isAboveSmallScreens
          ? `${getPageDimens()}`
          : "h-screen overflow-hidden pb-[calc(60px)]"
      } w-full snap-start items-start`}
      id="map"
    >
      <div
        className={`${
          isAboveSmallScreens ? `${getPageDimens()}` : "h-full "
        } flex w-full items-start justify-start`}
      >
        <div className="relative text-center">
          <img
            src={Map}
            alt="Map Location"
            className="h-auto w-full object-cover"
          />
          <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-70 p-4">
            <h1 className="text-lg font-bold text-white">EveryBotics Srl</h1>
            <p className="text-white">Via G. Di Biasio, 43</p>
            <p className="text-white">03043 Cassino (FR), Italia</p>
          </div>
        </div>

        {
        {!isLoaded ? (
          <h1>Loading...</h1>
        ) : (
          <GoogleMap
            mapContainerClassName="map-container"
            center={center}
            zoom={15}
            onLoad={onLoad}
            options={{
              styles: customMapStyle,
              gestureHandling: "none",
              mapTypeControl: false,
            }}
          >
            {map && (
              <Marker
                position={{ lat: marker.lat, lng: marker.lng }}
                icon={{
                  url: LogoMarker,
                  scaledSize: new window.google.maps.Size(64, 64),
                }}
                onClick={handleMarkerClick} // Attach the click event handler
              />
            )}
            {infoWindowOpen && map && (
              <InfoWindow
                position={{ lat: marker.lat, lng: marker.lng }}
                onCloseClick={() => setInfoWindowOpen(false)} // Close the InfoWindow when the close button is clicked
              >
                <div>
                  <p className="mb-2 font-semibold">EveryBotics Srl</p>
                  <p className="mb-2">Via G. Di Biasio, 03043 Cassino</p>
                  <button
                    className="text-cobalt "
                    onClick={() =>
                      window.open(
                        `https://www.google.com/maps?q=${marker.lat},${marker.lng}`
                      )
                    }
                  >
                    Visualizza su Google Mpas
                  </button>
                </div>
              </InfoWindow>
            )}
          </GoogleMap>
        )}
      </div>
    </div>
    
  }*/
};

export default MapCassino;
