import { NavLink } from "react-router-dom";
import { Screens, SelectedPage } from "../shared/types";
import { LocationDescriptorObject } from "history";
import Modal from "react-modal";
import { useState } from "react";
import TeamMember from "../scenes/pages/team/teamMember";
import { AnimatePresence, motion } from "framer-motion";
import useMediaQuery from "../hooks/useMediaQuery";

type Props = {
  image: string;
  name: string;
  job: string;
  index: number;
  website: string;
  setSelectedPage: (value: SelectedPage) => void;
};

const TeamImageMobile = ({
  image,
  name,
  job,
  index,
  website,
  setSelectedPage,
}: Props) => {
  const lowerCasePage = name.toLocaleLowerCase().replace(/ /g, "");
  Modal.setAppElement("#root");

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-20%",
      transform: "translate(-50%, -50%)",
      width: "fit-content",
      height: "fit-content",
      padding: "2rem",
      borderRadius: "0.5rem",
    },
  };

  const duration = 300;

  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
  };

  const transitionStyles = {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
  };

  const isAboveHugeScreen = useMediaQuery(Screens.Huge);
  const isAboveLargeScreen = useMediaQuery(Screens.Large);
  const isAboveMediumScreen = useMediaQuery(Screens.Medium);
  const isAboveSmallScreen = useMediaQuery(Screens.Small);

  function getTitleString(): String {
    if (isAboveHugeScreen) {
      return "text-2xl";
    } else if (isAboveLargeScreen) {
      return "text-2xl";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "text-base";
    } else {
      return "text-base";
    }
  }

  function getSubtitleString(): String {
    if (isAboveHugeScreen) {
      return "text-xl";
    } else if (isAboveLargeScreen) {
      return "text-xl";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "text-xs";
    } else {
      return "text-sm";
    }
  }

  function getArrowDimens(): String {
    if (isAboveHugeScreen) {
      return "text-4xl mt-4";
    } else if (isAboveLargeScreen) {
      return "text-4xl mt-4";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "text-xl ";
    } else {
      return "text-sm";
    }
  }

  function getBoxOptions(): String {
    if (isAboveHugeScreen) {
      return "gap-4";
    } else if (isAboveLargeScreen) {
      return "gap-4";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "gap-4";
    } else {
      return "gap-2";
    }
  }

  return (
    <div
      className={`flex h-full w-full flex-col items-center justify-start overflow-hidden px-4 py-4 ${getBoxOptions()}`}
      style={{ border: "2px solid white" }}
    >
      <div className="brightness-1100  relative h-full w-full contrast-100 saturate-150 filter">
        <div
          className="absolute  h-full w-full bg-cover  bg-center"
          style={{ backgroundImage: `url(${image})` }}
        ></div>
        <div
          className={`absolute inset-0  h-full ${
            index % 2 == 0 ? "bg-peach" : "bg-cobalt"
          } opacity-70`}
          style={{ mixBlendMode: "multiply" }}
        ></div>
      </div>
      <div className="flex  flex-col items-center justify-start gap-0">
        <div className={`${getTitleString()} font-semibold text-white`}>
          {name}
        </div>
        <div
          className={`${getSubtitleString()} text-center  italic text-white`}
        >
          {job}
        </div>{" "}
      </div>
    </div>
  );
};

export default TeamImageMobile;
