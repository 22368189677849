import BannerImage from "../../../../assets/banner.png";
import MouseParticles from "../../../../components/MouseParticles";
import YellowArrow from "../../../../assets/vertical_line_arrow.png";
import BannerTeam from "../../../../assets/banner_team.png";
import { useEffect, useRef, useState } from "react";
import TeamImage from "../../../../components/teamImage";
import DiVito from "../../../../assets/divitoBlack.png";
import Gillini from "../../../../assets/gilliniBlack.png";
import DiLillo from "../../../../assets/dililloBlack.png";
import DElia from "../../../../assets/deliaBlack.png";
import Marino from "../../../../assets/marinoBlack.png";
import Arrichiello from "../../../../assets/arrichielloBlack.png";
import Antonelli from "../../../../assets/antonelliBlack.png";
import { Screens, SelectedPage } from "../../../../shared/types";
import useMediaQuery from "../../../../hooks/useMediaQuery";
import { useTranslation } from "react-i18next";

type Props = { setSelectedPage: (value: SelectedPage) => void };

const Team = ({ setSelectedPage }: Props) => {

  const isAboveHugeScreen = useMediaQuery(Screens.Huge);
  const isAboveLargeScreen = useMediaQuery(Screens.Large);
  const isAboveMediumScreen = useMediaQuery(Screens.Medium);
  const isAboveSmallScreen = useMediaQuery(Screens.Small);

  function getPageDimens(): String {
    if (isAboveHugeScreen) {
      return "h-[calc(100vh-180px)]]";
    } else if (isAboveLargeScreen) {
      return " h-[calc(100vh-180px)]";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return " h-[calc(100vh-120px)]";
    } else {
      return "h-[calc(100vh-120px)]]";
    }
  }
  
  const {t, i18n} = useTranslation();

  return (
    <div
      className={`relative ${getPageDimens()} w-full snap-start overflow-hidden bg-cover bg-center bg-no-repeat p-[calc(60px)]`}
      id="team"
      style={{
        backgroundImage: `url(${BannerTeam})`,
      }}
    >
      <div className="flex h-full w-full flex-col  items-start justify-start gap-12 ">
        <div className="flex h-1/2 w-full flex-row  items-center justify-start gap-12  ">
          <TeamImage
            image={DiVito}
            name="Daniele Di Vito"
            job={t("chiefExecutiveOfficer")}
            index={1}
            website="https://www.danieledivitoengineering.it/"
            setSelectedPage={setSelectedPage}
          />
          <TeamImage
            image={Gillini}
            name="Giuseppe Gillini"
            job={t("chiefTechnologyOfficer")}
            index={2}
            website="https://www.giuseppegillini.it/"
            setSelectedPage={setSelectedPage}
          />
          <TeamImage
            image={DiLillo}
            name="Paolo Di Lillo"
            job={t("chairman")}
            index={3}
            website="https://paolodilillo.wordpress.com/"
            setSelectedPage={setSelectedPage}
          />
          <TeamImage
            image={DElia}
            name="Ciro D'Elia"
            job={t("adCom")}
            index={4}
            website="https://www.unicas.it/didattica/docenti/schedadocente.aspx?nome_cognome=ciro_d%27elia"
            setSelectedPage={setSelectedPage}
          />
        </div>
        <div className="flex h-1/2 w-full flex-row  items-center justify-start gap-12 ">
          <TeamImage
            image={Marino}
            name="Alessandro Marino"
            job={t("adCom")}
            index={5}
            website="http://webuser3.unicas.it/alessandro-marino/index.php"
            setSelectedPage={setSelectedPage}
          />
          <TeamImage
            image={Arrichiello}
            name="Filippo Arrichiello"
            job={t("viceChairman")}
            index={6}
            website="http://webuser.unicas.it/arrichiello/"
            setSelectedPage={setSelectedPage}
          />
          <TeamImage
            image={Antonelli}
            name="Gianluca Antonelli"
            job={t("adCom")}
            index={7}
            website="https://sites.google.com/unicas.it/gianluca-antonelli"
            setSelectedPage={setSelectedPage}
          />
        </div>
      </div>
    </div>
  );
};

export default Team;
